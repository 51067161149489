import React, { createContext, useState, useEffect } from 'react';
 
export const BusinessContext = createContext();
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
 
export const BusinessProvider = ({ children }) => {
  const [business, setBusiness] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
 
  const fetchBusiness = async () => {
    setLoading(true);
    setError(null);
 
    try {
      const response = await fetch(`${BASE_URL}/business`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      console.log(data);
      if (data.status === 'success') {
        setBusiness(data.data);
      } else {
        setError('Failed to retrieve Users.');
      }
    } catch (err) {
      setError('An error occurred while fetching Users.');
    } finally {
      setLoading(false);
    }
  };
 
  useEffect(() => {
    fetchBusiness();
  }, []);
 
 
  return (
    <BusinessContext.Provider
      value={{
        business,
        loading,
        error,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};