import React from 'react';
import PushRightDoor from './PushRightDoor';
import PushLeftDoor from './PushLeftDoor';
import PullRightDoor from './PullRightDoor';
import PullLeftDoor from './PullLeftDoor';

const DoorImage = ({ points, doorHeight,doorHandle, doorCloser,doorColor, offsetX = 0, offsetY = 0, opening, orientation }) => {
  if (!Array.isArray(points) || points.length < 4) {
   
    return null;
  }

  const [x1, y1, x2, y2] = points;


  const doorWidth = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)) / 100 || 0.1;
  const doorHeightInMeters = parseFloat(doorHeight) / 1000;

  const position = [
    (x1 + x2) / 2 / 100 - offsetX,
    doorHeightInMeters / 2.2,
    (y1 + y2) / 2 / 100 - offsetY,
  ];

  

  const angle = Math.atan2(y2 - y1, x2 - x1);


  let DoorComponent;
  if (opening === "Push" && orientation === "Right") DoorComponent = PushRightDoor;
  else if (opening === "Push" && orientation === "Left") DoorComponent = PushLeftDoor;
  else if (opening === "Pull" && orientation === "Right") DoorComponent = PullRightDoor;
  else if (opening === "Pull" && orientation === "Left") DoorComponent = PullLeftDoor;

  if (!DoorComponent) {
    
    return null;
  }

  return (
    <DoorComponent
      position={position}
      rotation={[0, -angle, 0]}
      width={doorWidth}
      height={doorHeightInMeters}
      handle={doorHandle}
      closer={doorCloser}
      color={doorColor}
    />
  );
};

export default DoorImage;