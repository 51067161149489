import React from 'react';
import { useNavigate } from 'react-router-dom';
import './upload-screen.css'; // Assuming you have a CSS file for custom styles
import { Container, Paper, Typography, Button, Box } from '@mui/material';
import UploadImage from '../../assets/upload.png';

const UploadScreen = () => {
    const navigate = useNavigate();
    
    const handleWithoutBgClick = (path) => {
        navigate(path);
    };

    const handleWithBgClick = (path) => {
        navigate(path);
    };
    
    return (
        <Container id="upload-screen" maxWidth="sm">
            <div className='upload-desc'>
                <Typography className="poppins-semiBold" gutterBottom>
                    Start new partition design
                </Typography>
                <Typography className="roboto-font" gutterBottom>
                    Create your project from a blank plan or shape and give free rein to your imagination.
                </Typography>
            </div>
           
            <Paper onClick={() => handleWithBgClick('/Upload_your_local_dxf_or_pdf')} elevation={2} style={{ padding: '20px', textAlign: 'center', width: '383px', height: '211px', border: '1px dashed #757575' }}>
                <Box
                    component="img"
                    className='upload-img'
                    sx={{ flexGrow: 0 }}
                    alt="Upload"
                    src={UploadImage}
                />
                <Typography id="uploadModelFile" className="roboto-font text-bold" gutterBottom>
                    Upload background
                </Typography>
                <Typography className="roboto-font small-font" color="textSecondary">
                    File formats accepted: Dxf or PDF (max. 20MB)
                </Typography>
            </Paper>
            
            <div className="button-container">
                <Typography className="poppins-semiBold" color="textSecondary">
                    <div className="separator">
                        <span>Or</span>
                    </div>
                </Typography>
                <Button className="continue-button" variant="contained" onClick={() => handleWithoutBgClick('/designWithoutBg')}>
                    Continue without background
                </Button>
            </div>
        </Container>
    );
};

export default UploadScreen;