// Function to map rules by rule name
export const getRuleByName = (rules, ruleName) => {
    const rule = rules.find(r => r.rule_name === ruleName);
    return rule || {};
  };
  
  // Function to extract constants
  export const getConstants = (rules) => {
    const constants = {};
    rules.forEach(rule => {
      if (rule.rule_type === "Constant" && rule.active) {
        constants[rule.rule_name] = parseFloat(rule.rule_constant);
      }
    });
    return constants;
  };
  