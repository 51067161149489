import React, { Suspense, useRef, useContext, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { Box } from '@mui/material';
import FlagContext from "../../../flag-context";
import { OrbitControls, Sky, Stage } from '@react-three/drei';
import PartitionSegment from './3D_Glass/Partition';
import DoorImage from './3D_Glass/Door';

export default function Viewer() {
  const ref = useRef();
  const { flag, updateFlag } = useContext(FlagContext);

  useEffect(() => {
    updateFlag("isDesign", true);
    console.log("flag.Data2D:", JSON.stringify(flag.Data2D, null, 2)); // Log flag.Data2D for debugging
    return () => updateFlag("isDesign", false);
  }, [flag.Data2D]);


  const calculateCenterOffset = (data) => {
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
    data.forEach(line => {
      const [x1, y1, x2, y2] = line.points;
      minX = Math.min(minX, x1, x2);
      maxX = Math.max(maxX, x1, x2);
      minY = Math.min(minY, y1, y2);
      maxY = Math.max(maxY, y1, y2);
    });
    const centerX = (minX + maxX) / 2 / 100;
    const centerY = (minY + maxY) / 2 / 100;
    return [centerX, centerY];
  };
  
  
  
  const [offsetX, offsetY] = calculateCenterOffset(flag.Data2D);

  return (
    <Box
      style={{
        display: "flex",
        flexGrow: "1",
        position: "absolute",
        right: "3%",
        top: "10%",
        width: "100%",
        height: "90vh",
      }}
    >
      <Canvas shadows
          dpr={[1, 2]}
          camera={{
            fov: 50,
            position: [0, 20, 50], // Ensure camera is positioned far enough
            near: 0.1,
            far: 1000,
          }}>
        <ambientLight />
        <pointLight position={[10, 20, 10]} />
        <Suspense fallback={null}>
          <Sky distance={450000} sunPosition={[0, 1, 0]} />
          <Stage controls={ref} preset="rembrandt" environment="apartment" intensity={1} />
          
        

{flag.Data2D?.map((item, index) => {
  if (item.mode === "Partition") {
    return item.segments?.map((segment, segmentIndex) => (
      <PartitionSegment
        key={`${item.id}-${segmentIndex}`}
        x1={segment.x1}
        y1={segment.y1}
        x2={segment.x2}
        y2={segment.y2}
        panelHeight={item['Partition Height']}
        offsetX={offsetX}
        offsetY={offsetY}
        polycarbonateShape={item['Glass Joint']}
        profileFinish={item['Profile Finish']}
      />
    ));
  } 
  else if (item.mode === "Door") {
    return item.segments?.map((segment, segmentIndex) => (
      <DoorImage
        key={`${item.id}-${segmentIndex}`}
        points={[segment.x1, segment.y1, segment.x2, segment.y2]} // Pass segment coordinates as points
        doorHeight={item["Door Height"]}
        doorHandle={item["Door Handle"]}
        doorCloser={item["Door Closer"]}
        opening={item['Opening']}
        orientation={item['Orientation']}
        doorColor={item['Profile Finish']}
        offsetX={offsetX}
        offsetY={offsetY}
      />
    ));
  }
  return null;
})}

        </Suspense>
        <OrbitControls />
      </Canvas>
    </Box>
  );
}