import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Radio,
  Grid,
  InputAdornment,
  Slider,
  Link,
  Button,
  Snackbar,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon, InfoOutlined } from "@mui/icons-material";
import FlagContext from "../flag-context";
import drawTemp from '../assets/drawTemp.png';


const Properties = ({ systemData, templateData }) => {
  const { flag, updateFlag } = useContext(FlagContext);
  const [toastOpen, setToastOpen] = useState(false);
  const [customColorOpen, setCustomColorOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#000000");

  // State to hold dynamic form values
  const [formValues, setFormValues] = useState({
    "Panel Height": "2400",
    profileFinish: "Black Matt",
    glassThickness: "10mm", // Set a default value
    glassType: "Toughened Glass",
    polycarbonateShape: "Two-way Polycarbonate",
    gasketColor: "Default Black",
  });

  const GlassThicknessAcousticValue = {
    "10mm": 34,
    "11.52 PVB": 34,
    "11.52 APVB": 34,
    "12mm": 36,
    "12.52 PVB": 36,
    "12.52 APVB": 36,
    "13.52 PVB": 38,
    "13.52 APVB": 36,
  }

  const AcousticValueGlassThickness = {
    34 : ["10mm", "11.52 PVB", "11.52 APVB"],
    36 : ["12mm", "12.52 PVB", "12.52 APVB", "13.52 APVB"],
    38 : ["13.52 PVB"]
  }

  const MIN = 2000
  const MAX = 3000
  const defaultHeigth = 2400

  const data = {
    sleek: {
      partition: {
        "Partition Height": { type: "text", options: [], min: 2000, max: 3000 },
        "Profile Finish": {
          type: "dropdown",
          options: [
            "Black Matt",
            "Natural Anodized",
            "Champagne",
            "Rose Gold",
            "Custom",
          ],
        },
        "Glass Thickness": {
          type: "dropdown",
          options: [
            "10mm",
            "11.52 PVB",
            "11.52 APVB",
            "12mm",
            "12.52 PVB",
            "12.52 APVB",
            "13.52 PVB",
            "13.52 APVB",
          ],
        },
        "Glass Type": {
          type: "dropdown",
          options: ["Toughened Glass", "Laminated Glass"],
        },
        Acoustic: {
          type: "display",
          options: {
            "10mm": "34 dB",
            "11.52 PVB": "34 dB",
            "11.52 APVB": "34 dB",
            "12mm": "36 dB",
            "12.52 PVB": "36 dB",
            "12.52 APVB": "36 dB",
            "13.52 PVB": "38 dB",
            "13.52 APVB": "36 dB",
          },
        },
        "Glass Joint": {
          type: "dropdown",
          options: ["Two-way Polycarbonate", "Mullion", "90 degree Aluminium"],
        },
        "Gasket Color": {
          type: "dropdown",
          options: ["Default Black", "Optional Gray"],
        },
      },
      door: {
        "Door Height": { type: "text", options: [], min: 2000, max: 3000 },
        Glass: {
          type: "display",
          options: ["10mm Toughened Glass", "Laminated Glass"],
        },
        Finish: {
          type: "dropdown",
          options: [
            "Black Matt",
            "Natural Anodized",
            "Champagne",
            "Rose Gold",
            "Custom",
          ],
        },
        Orientation: { type: "dropdown", options: ["Left", "Right"] },
        Opening: { type: "dropdown", options: ["Pull", "Push"] },
        "Door Handle": {
          type: "dropdown",
          options: ["Lever Handle", "Offset Handle"],
        },
        "Door Lock": { type: "dropdown", options: ["Sash Lock", "Dead Lock"] },
        "Door Closer": {
          type: "dropdown",
          options: ["Exposed Closer", "Concealed Closer"],
        },
        // "Door Dropdown Seal": {
        //   type: "dropdown",
        //   options: ["same as width of the door"],
        // },
        "Door Stopper": { type: "dropdown", options: ["No Stopper", "Other"] },
        "Tower bolt": { type: "dropdown", options: ["No Tower bolt", "Other"] },
      },
    },
  };

  const DoorDir = [
    {
      Orientation: "Right",
      Opening: "Push",
      doordirection: 0,
    },
    {
      Orientation: "Left",
      Opening: "Push",
      doordirection: 1,
    },
    {
      Orientation: "Right",
      Opening: "Pull",
      doordirection: 2,
    },
    {
      Orientation: "Left",
      Opening: "Pull",
      doordirection: 3,
    },
  ];

  // Handles changes in input values dynamically
  const handleChange = (name, value) => {
    if (name === "Profile Finish" && value === "Custom") {
      setCustomColorOpen(true);
    }
    if (flag.isSelected !== false) {
      updateFlag(
        "Data2D",
        flag.Data2D.map((line, index) => {
          // if(name === "Partition Height"){

          //   let obj = { ...line};

          //   if(obj.mode === "Partition"){
          //     obj[name] = value
          //   }
          //   else if(obj.mode === "Door"){
          //     obj["Door Height"] = value
          //   }

          //   return obj
          // }
          // else
          if (flag.isSelected === line.id) {
            let obj = { ...line, [name]: value };

            if (name === "Glass Thickness"){
              obj["Acoustic Value"] = GlassThicknessAcousticValue[value]
              let GlassType = value.includes("mm") ? "Toughend Glass" : value.includes("PVB") ? "Laminated Glass" : obj["Glass Type"]
              obj["Glass Type"] = GlassType
            } 
              
            if (name === "Acoustic Value"){
              obj["Glass Thickness"] = AcousticValueGlassThickness[value][0]
              let GlassType = obj["Glass Thickness"].includes("mm") ? "Toughend Glass" : obj["Glass Thickness"].includes("PVB") ? "Laminated Glass" : obj["Glass Type"]
              obj["Glass Type"] = GlassType
            } 

            if (name === "Orientation" || name === "Opening") {
              let find = DoorDir.find(
                (each) =>
                  each.Opening === obj.Opening &&
                  each.Orientation === obj.Orientation
              );
              obj.doordirection = find.doordirection;
            }
            return obj;
          } else return { ...line };
        })
      );
    } else{
      updateFlag(
        `${flag.TwoD.mode}Properties`,
        {
          [name]: value,
          ...(
            name === "Glass Thickness" && {
              "Acoustic Value": GlassThicknessAcousticValue[value],
              "Glass Type": value.includes("mm")
                ? "Toughened Glass"
                : value.includes("PVB")
                ? "Laminated Glass"
                : flag[`${flag.TwoD.mode}Properties`]["Glass Type"], // Preserve existing type if not APVB or PVB
            }
          ),
          ...(
            name === "Acoustic Value" && {
              "Glass Thickness": AcousticValueGlassThickness[value][0],
              "Glass Type": AcousticValueGlassThickness[value][0].includes("mm")
                ? "Toughened Glass"
                : AcousticValueGlassThickness[value][0].includes("PVB")
                ? "Laminated Glass"
                : flag[`${flag.TwoD.mode}Properties`]["Glass Type"], // Preserve existing type if not APVB or PVB
            }
          ),
        }
      );
       
    } 
  };

  // Closes the custom color modal
  const handleCustomColorClose = () => {
    setCustomColorOpen(false);
  };

  // Toast close handler
  const handleToastClose = () => {
    setToastOpen(false);
  };
  const OnDraw = () => {
    updateFlag("ExpandProperties", false);
  };

  useEffect(() => {
    let DATA =
      flag.Properties.systems[flag.TwoD.divisionIndex]
        .products[flag.TwoD.modeIndex].templates[flag.TwoD.typeIndex]
        .attributes;

    let properties = {};

    DATA.forEach((each) => {
      if(["Partition Height", "Door Height"].includes(each.component_attribute_name) && !each.defaultValue ){
        properties[each.component_attribute_name] = defaultHeigth;
      }
      else if ("defaultValue" in each) {
        properties[each.component_attribute_name] = each.defaultValue;
      } else {
        let find = each.values.find((one) => one.isDefault);
        properties[each.component_attribute_name] = find
          ? find.comp_attribute_value_name
          : "";
      }

      // changing to number only if it "Acoustic Value"
      if(each.component_attribute_name === "Acoustic Value"){
        properties[each.component_attribute_name] = Number(properties[each.component_attribute_name])
      }
    });
    console.log(properties);

    updateFlag(`${flag.TwoD.mode}Properties`, properties, true);
  }, []);

  // Renders fields based on their type
  const renderField = (field) => {
    let label = field.component_attribute_name;
    const VALUE =
      flag.isSelected !== false
        ? flag.Data2D.find((each) => each.id === flag.isSelected)[label]
        : flag[flag.TwoD.mode + "Properties"][label];

    switch (field.component_type) {
      case "text":
        const min = field.min ? field.min : MIN
        const max = field.max ? field.max : MAX
        if(label !== "Glass Width"){
          return (
            <div style={{ marginTop: "20px", marginLeft: "15px", marginBottom: "20px" }}>
              <TextField
                id="outlined-adornment-panel-height"
                style={{ fontFamily: "Roboto", fontSize: "12px" }}
                sx={{
                  height: 40,
                  width: 215,
                  "& .MuiInputBase-input.MuiOutlinedInput-input": {
                    padding: "10px",
                  },
                  "& #outlined-adornment-panel-height-label": {
                    fontSize: "12px !important",
                    top: "-5px",
                  },
                  "& #outlined-adornment-panel-height": {
                    fontSize: "12px !important",
                  },
                  "& #outlined-adornment-panel-height-helper-text": {
                    margin: "0px",
                  },
                }}
                label={label}
                value={VALUE}
                onChange={(e) => handleChange(label, e.target.value)}
                onBlur={() => {
                  const value = parseInt(VALUE, 10);
                  if (value < min || value > max) {
                    setToastOpen(true);
                    handleChange(label, max.toString());
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span style={{ fontSize: "12px" }}>mm</span>
                    </InputAdornment>
                  ),
                  inputProps: {
                    "aria-label": "panel height",
                    min: min,
                    max: max,
                  },
                }}
                variant="outlined"
                helperText={
                  <Typography
                    variant="caption"
                    display="flex"
                    gutterBottom
                    color="textSecondary"
                    sx={{
                      fontSize: "0.5rem",
                      margin: "0px",
                    }}
                  >
                    <IconButton size="small" color="info">
                      <InfoOutlined sx={{ fontSize: "1rem" }} fontSize="small" />
                    </IconButton>
                    <span
                      style={{
                        fontSize: "0.5rem",
                        margin: "0px",
                        paddingTop: "5px",
                        textAlign: "left",
                      }}
                    >
                      Min value is {min} mm and Max value is {max} mm{" "}
                    </span>
                  </Typography>
                }
              />
            </div>
          );
        }
      case "slider":
        const valuetext = (value) => `${value}dB`;
        return (
          <>
            {label === "Acoustic Value" && (
              <>       
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  color: "#191919",
                  margin: "-5px 0 0 0",
                  paddingTop: "5px",
                  textAlign: "left",
                }}
              >
                <span
                  style={{
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "12px",
                    fontFamily: "Roboto",
                    marginLeft: "20px",
                  }}
                >
                  {label} :
                </span>
              </Typography>        
              <Box sx={{ width: 150, marginLeft: "35px", marginTop: '10px'}}>
                <Slider
                  aria-label={label}
                  value={VALUE}
                  getAriaValueText={valuetext}
                  step={2}
                  marks={field.values.map((each) => ({
                    label: Number(each.comp_attribute_value_name),
                    value: Number(each.comp_attribute_value_name),
                  }))}
                  min={34}
                  max={38}
                  onChange={(e) => handleChange(label, e.target.value)}
                  valueLabelDisplay="auto"
                />
              </Box>              
              </>
            )}
          </>
        );
      case "dropdown":
        const Val_of_Acoustic = flag.isSelected !== false
        ? flag.Data2D.find((each) => each.id === flag.isSelected)["Acoustic Value"]
        : flag[flag.TwoD.mode + "Properties"]["Acoustic Value"];
        const Val_of_GlassThickness = flag.isSelected !== false
        ? flag.Data2D.find((each) => each.id === flag.isSelected)["Glass Thickness"]
        : flag[flag.TwoD.mode + "Properties"]["Glass Thickness"];
        return (
          <>
            {![
              "Door Handle",
              "Door Lock",
              "Door Closer",
              "Door Dropdown Seal",
            ].includes(label) && (
              <TextField
                select
                sx={{
                  height: 40,
                  width: 215,
                  marginLeft: "15px",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  "& .MuiInputBase-root": {
                    height: 48,
                  },
                  "& .MuiSelect-select": {
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                label={label}
                value={VALUE}
                onChange={(e) => handleChange(label, e.target.value)}
                fullWidth
                SelectProps={{
                  native: true,
                }}
                InputProps={{
                  style: { fontFamily: "Roboto", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Roboto", fontSize: "12px" },
                }}
              >
                {field.values.map((option, index) => {
                  if(label === "Glass Thickness"){
                    if(AcousticValueGlassThickness[Val_of_Acoustic] && AcousticValueGlassThickness[Val_of_Acoustic].includes(option.comp_attribute_value_name)){
                      return(
                        <option key={index} value={option.comp_attribute_value_name}>
                          {option.comp_attribute_value_name}
                        </option>
                      )
                    }
                  }
                  else if(label === "Glass Type" && Val_of_GlassThickness){
                   if(Val_of_GlassThickness.includes("mm") && option.comp_attribute_value_name === "Toughend Glass"){
                      return(
                        <option key={index} value={option.comp_attribute_value_name}>
                          {option.comp_attribute_value_name}
                        </option>
                      )
                    }
                    else if(Val_of_GlassThickness.includes("PVB") && option.comp_attribute_value_name === "Laminated Glass"){
                      return(
                        <option key={index} value={option.comp_attribute_value_name}>
                          {option.comp_attribute_value_name}
                        </option>
                      )
                    }            
                  }
                  else {
                    return(
                      <option key={index} value={option.comp_attribute_value_name}>
                        {option.comp_attribute_value_name}
                      </option>
                    )
                  }
                })}
              </TextField>
            )}
            {[
              "Door Handle",
              "Door Lock",
              "Door Closer",
              "Door Dropdown Seal",
            ].includes(label) && (
              <>
                {/* <InputLabel
                  item
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    color: "#000",
                    marginLeft: "15px",
                  }}
                >
                  {label}
                </InputLabel> */}
                <TextField
                  select
                  key={label}
                  sx={{
                    height: 40,
                    width: 135,
                    marginLeft: "15px",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    "& .MuiInputBase-root": {
                      height: 48,
                    },
                    "& .MuiSelect-select": {
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                  label={label.replace(/_/g, " ")}
                  name={label}
                  value={VALUE}
                  onChange={(e) => handleChange(label, e.target.value)}
                  fullWidth
                  SelectProps={{
                    native: true,
                  }}
                  InputProps={{
                    style: { fontFamily: "Roboto", fontSize: "12px" },
                  }}
                  InputLabelProps={{
                    style: { fontFamily: "Roboto", fontSize: "12px" },
                  }}
                >
                  <option value="" hidden />
                  {field.values.map((option, index) => (
                    <option
                      key={index}
                      value={option.comp_attribute_value_name}
                    >
                      {option.comp_attribute_value_name}
                    </option>
                  ))}
                </TextField>
                <TextField
                  select
                  key={label}
                  sx={{
                    marginLeft: "5px",
                    width: 80,
                    height: 40,
                  }}
                  style={{ fontFamily: "Roboto", fontSize: "12px" }}
                  label={"Make"}
                  name={label}
                  value={"Saint Gobain"}
                  onChange={(e) => handleChange(label, e.target.value)}
                  fullWidth
                  SelectProps={{
                    native: true,
                  }}
                  InputProps={{
                    style: { fontFamily: "Roboto", fontSize: "12px" },
                  }}
                  InputLabelProps={{
                    style: { fontFamily: "Roboto", fontSize: "12px" },
                  }}
                >
                  <option value="" hidden />
                  <option value={"Saint Gobain"}>Saint Gobain</option>
                  <option value={"Others"}>Others</option>
                </TextField>
              </>
            )}
          </>
        );
      case "radio":
        return (
          <RadioGroup
            value={VALUE}
            onChange={(e) => handleChange(label, e.target.value)}
          >
            {field.values.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}{" "}
          </RadioGroup>
        );
      case "display":
        if (label == "Acoustic") {
          const val =
            flag.isSelected !== false
              ? flag.Data2D.find((each) => each.id === flag.isSelected)[
                  "Glass Thickness"
                ]
              : flag[flag.TwoD.mode + "Properties"]["Glass Thickness"];
          return (
            <>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontStyle: "italic",
                  color: "#191919",
                  margin: "-15px 0 0 20px",
                  paddingTop: "5px",
                  textAlign: "left",
                }}
              >
                <span
                  style={{
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "12px",
                    fontFamily: "Roboto",
                    marginLeft: "5px",
                  }}
                >
                  Acoustic value :{" "}
                </span>
                {field.values[val]}
              </Typography>
              <Typography
                variant="caption"
                display="flex"
                gutterBottom
                color="textSecondary"
                sx={{
                  fontSize: "0.5rem",
                  marginLeft: "15px",
                }}
              >
                <IconButton size="small" color="info">
                  <InfoOutlined sx={{ fontSize: "1rem" }} fontSize="small" />
                </IconButton>
                <span
                  style={{
                    fontSize: "0.5rem",
                    margin: "0px",
                    paddingTop: "5px",
                    textAlign: "left",
                  }}
                >
                  Acoustic value is subject to change based on the glass
                  thickness.
                </span>
              </Typography>
            </>
          );
        } else
          return (
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: "12px",
                color: "#191919",
                margin: "-5px 0 0 0",
                paddingTop: "5px",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: "12px",
                  fontFamily: "Roboto",
                  marginLeft: "20px",
                }}
              >
                {label} :
              </span>
              {VALUE}
            </Typography>
          );
      default:
        return null;
    }
  };
  const ChangeTemplate = () => {
    updateFlag("TwoD", { type: "" });
  };

  return (
    <Box
    sx={{
      width: "265px",
      height: "450px",
      display: "flex",
      flexDirection: "column",
      textAlign: "left",
      position: "relative", // For sticky positioning
    }}
  >
    <Box display="flex" justifyContent="space-between">
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          color: "#191919",
          marginLeft: "15px",
        }}
      >
        Add {systemData}
      </Typography>
      <Link
        onClick={ChangeTemplate}
        sx={{ fontFamily: "Roboto", fontSize: "12px", color: "blue" }}
      >
        Change template
      </Link>
    </Box>
  
    <Typography
      style={{
        fontFamily: "Roboto",
        fontSize: "13px",
        color: "#191919",
        marginLeft: "15px",
        marginTop: "10px",
        marginBottom: "-10px",
        textAlign: "left",
      }}
    >
      {templateData}
    </Typography>
  
    {/* Scrollable content starts here */}
    <Box
      sx={{
        height: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
        maxHeight: '400px',
        mt: 0.5, // Margin to separate from previous section
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5E8DE9",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#5E8DE9",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1",
          borderRadius: "5px",
        },
      }}
    >
      {flag.Properties.systems[
        flag.TwoD.divisionIndex
      ].products[flag.TwoD.modeIndex].templates[
        flag.TwoD.typeIndex
      ].attributes.map((each, index) => (
        <Box key={index} my={5}>
          {renderField(each)}
        </Box>
      ))}
  
      <Snackbar
        open={toastOpen}
        autoHideDuration={8000}
        onClose={handleToastClose}
        message="Invalid panel height entered"
        action={
          <IconButton size="small" color="inherit" onClick={handleToastClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Box>
    {/* End of scrollable content */}
  
    {flag.isSelected === false && (
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
          zIndex: 1,
          padding: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            color: "white",
            backgroundColor: "#214386",
            width: "100%",
          }}
          onClick={OnDraw}
          startIcon={
            <img
              src={drawTemp}
              alt="Draw Icon"
              style={{ width: 20, height: 20 }}
            />
          }
        >
          Draw
        </Button>
      </Box>
    )}
  </Box>
  
  );
  
};

export default Properties;