import React, { createContext, useState, useEffect, useCallback } from 'react';

export const ProjectContext = createContext();
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const ProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [designs, setDesigns] = useState([]);
  const [businessName, setBusinessName] = useState(''); 
  const [customerName, setCustomerName] = useState('');
  const [location, setLocation] = useState('');
  const [projectName, setProjectName] = useState(''); // New state for project name
  const [designName, setDesignName] = useState('');   // New state for design name
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
  });

  const fetchProjects = async (page = 1, limit = 10) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${BASE_URL}/projects?page=${page}&limit=${limit}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      if (data.status === 'success') {
        setProjects(data.data);
        setPagination({
          page: data.pagination.page,
          limit: data.pagination.limit,
          total: parseInt(data.pagination.total, 10),
        });
      } else {
        setError('Failed to retrieve projects.');
      }
    } catch (err) {
      setError('An error occurred while fetching projects.');
    } finally {
      setLoading(false);
    }
  };

  const fetchDesignByProjectId = useCallback(async (projectId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${BASE_URL}/projects/design/${projectId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      if (data.status === 'success') {
        setDesigns(data.data);
      } else {
        setError('Failed to retrieve Designs.');
      }
    } catch (err) {
      setError('An error occurred while fetching Designs.');
    } finally {
      setLoading(false);
    }
  }, []);

  const addDesign = async (newDesign) => {
    try {
      const response = await fetch(`${BASE_URL}/designs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify(newDesign),
      });

      const data = await response.json();

      if (data.status === 'success') {
        fetchProjects();
      } else {
        setError('Failed to add design.');
      }
    } catch (err) {
      setError('An error occurred while adding the design.');
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const addProject = async (newProject) => {
    try {
      const { business_name, design_count, designs, project_status, ...filteredProject } = newProject;
      const response = await fetch(`${BASE_URL}/projects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify(filteredProject),
      });

      const data = await response.json();

      if (data.status === 'success') {
        fetchProjects();
      } else {
        setError('Failed to add project.');
      }
    } catch (err) {
      setError('An error occurred while adding the project.');
    }
  };

  const updateProject = async (projectId, updatedProject) => {
    try {
      const response = await fetch(`${BASE_URL}/projects/${projectId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedProject),
      });

      const data = await response.json();

      if (data.status === 'success') {
        fetchProjects();
      } else {
        setError('Failed to update project.');
      }
    } catch (err) {
      setError('An error occurred while updating the project.');
    }
  };

  const deleteProject = async (projectId) => {
    try {
      const response = await fetch(`${BASE_URL}/projects/${projectId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (data.status === 'success') {
        fetchProjects();
      } else {
        setError('Failed to delete project.');
      }
    } catch (err) {
      setError('An error occurred while deleting the project.');
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        loading,
        error,
        pagination,
        fetchProjects,
        addProject,
        updateProject,
        deleteProject,
        designs,
        addDesign,
        fetchDesignByProjectId,
        businessName,
        setBusinessName,
        customerName,
        setCustomerName,
        location,
        setLocation,
        projectName, // Expose projectName
        setProjectName, // Expose setProjectName to update project name
        designName, // Expose designName
        setDesignName, // Expose setDesignName to update design name
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};