import React from 'react';
import { DoubleSide } from 'three';
import { RoundedBox, Cylinder } from '@react-three/drei';
import * as THREE from 'three';

const PullLeftDoor = ({ position, width, height, rotation, handle,closer, color  }) => {
  const glassThickness = 0.02; // Thickness for glass
  const frameThickness = 0.03; // Thickness for frames


  const frameWidthTotal = 250 / 1000;
  const frameHeightTotal = 250 / 1000;


  const glassWidth = width - frameWidthTotal * 2; // Recalculate glass width dynamically
const glassHeight = height - frameHeightTotal * 2; // Recalculate glass height dynamically


  const blackFrameSize = frameWidthTotal / 2.1; // Black frame size
  const darkYellowFrameSize = 0.01; // Dark yellow frame size
  const greenFrameSize = frameWidthTotal / 2;
  const greenFrameThickness = 0.06;

  const offset = 0.35;
  const adjustedPosition = [position[0], position[1] - (height - glassHeight) / 2 + offset, position[2]];








// Adjust closer X position dynamically based on door width
const doorCloserX = width / -5 - greenFrameSize / 12; // Dynamically align closer

const hingeRadius = 0.005;
const hingeHeight = 0.06;


  const topHingeOffset = 0.1; 
  const bottomHingeOffset = 0.1;
  const glassCenterY = glassHeight / 2;
  const hingeOffsetX = -(glassWidth / 2.01 + blackFrameSize + darkYellowFrameSize / 2);

  const hingePositions = [
    [hingeOffsetX, glassCenterY - topHingeOffset, glassThickness / 2 + 0.01],
    [hingeOffsetX, glassCenterY - topHingeOffset - hingeHeight - topHingeOffset, glassThickness / 2 + 0.01],
    [hingeOffsetX, -glassCenterY + bottomHingeOffset, glassThickness / 2 + 0.01],
    [hingeOffsetX, 0, glassThickness / 2 + 0.01],
  ];
  
  
  





// Dynamically calculate the black frame's center position

const blackFrameCenterX = width / 4 - blackFrameSize / 2;



 // Handle Position (Right Side)
 const handleOffsetX = glassWidth / 2 + blackFrameSize / 2;
 const handlePositionY = 0;
 const handlePositionFront = [handleOffsetX, handlePositionY, glassThickness / 2 + 0.05];












  
// Constants for black frame dimensions
const blackFrameHeight = glassHeight + 0.1; // Adjust height as per black frame specifics
const blackFrameWidth = glassWidth + 0.1;  // Adjust width as per black frame specifics

// Dynamic offsets based on the black frame
const doorCloserZ = glassThickness / 2 + frameThickness + 0.01; // Attach closer to the front side


const doorCloserY = blackFrameHeight / 1.895 - 0.05; // Attach closer near the top of the frame




// Define frame center positions
const frameCenterX = glassWidth / 2 + blackFrameSize / 2; // Horizontal center of the frame
const frameCenterY = 0; // Assume vertically centered






const colorMapping = {
  "Black Matt": "#1a1a1a",
  "Natural Anodized": "#626262",
  "Champagne": "#76694e",
  "Rose Gold": "#775041",
  Custom: "#000000", // Default custom color
};



const frameColor = colorMapping[color] || "red";

const hingeColorMapping = {
  "Black Matt": "#3b3c38",
  "Natural Anodized": "#797b7e",
  "Champagne": "#a0926f",
  "Rose Gold": "#7c7a7c",
  Custom: "#fffdfa", // Default custom color
};


const closerColorMapping = {
  "Black Matt": "#3b3c38",
  "Natural Anodized": "#797b7e",
  "Champagne": "#a0926f",
  "Rose Gold": "#a57830",
  Custom: "#dcdcdc", // Default custom color
};
const closerColor = closerColorMapping[color] || "#dcdcdc";

// Dynamic offset for the exposed closer based on the door width
const dynamicDoorCloserX = -((width / 2) + (blackFrameSize / 2) - 0.3 );
// Add small offset for fine adjustment





const dynamicDoorCloserY = blackFrameHeight / 1.85 - 0.05; // Adjust height near the top

const dynamicDoorCloserZ = glassThickness / 2 + frameThickness - 0.01;

  return (
    <group rotation={rotation} position={adjustedPosition}>
      {/* Glass Panel */}
      <mesh position={[0, -0.05, 0]}>
        <boxGeometry args={[glassWidth, glassHeight+0.3, glassThickness]} />
        <meshStandardMaterial color="#629677" transparent opacity={0.6} side={DoubleSide} clearcoat={1} 
          clearcoatRoughness={0.25}  />
      </mesh>

      {/* Black Frames */}
      <mesh position={[-(glassWidth / 2 + blackFrameSize / 2), 0, 0]}>
        <boxGeometry args={[blackFrameSize, glassHeight+0.3, frameThickness]} />
        <meshStandardMaterial color={frameColor} side={DoubleSide} clearcoat={1} 
          clearcoatRoughness={0.25} />
      </mesh>
      <mesh position={[(glassWidth / 2 + blackFrameSize / 2), 0, 0]}>
        <boxGeometry args={[blackFrameSize, glassHeight+0.3, frameThickness]} />
        <meshStandardMaterial color={frameColor}  side={DoubleSide} clearcoat={1} 
          clearcoatRoughness={0.25} />
      </mesh>
      <mesh position={[0, -(glassHeight / 1.99 + blackFrameSize / 2)-0.12, 0]}>
        <boxGeometry args={[glassWidth + 2 * blackFrameSize, blackFrameSize, frameThickness]} />
        <meshStandardMaterial color={frameColor}  side={DoubleSide} clearcoat={1} 
          clearcoatRoughness={0.25} />
      </mesh>
      <mesh position={[0, (glassHeight / 1.99 + blackFrameSize / 2), 0]}>
        <boxGeometry args={[glassWidth + 2 * blackFrameSize, blackFrameSize, frameThickness]} />
        <meshStandardMaterial color={frameColor}  side={DoubleSide} clearcoat={1} 
          clearcoatRoughness={0.25} />
      </mesh>

      {/* Dark Yellow Frames */}
      <mesh position={[-(glassWidth / 2 + blackFrameSize + darkYellowFrameSize / 2), 0, 0]}>
        <boxGeometry args={[darkYellowFrameSize, height, frameThickness]} />
        <meshStandardMaterial color={frameColor}  side={DoubleSide}  clearcoat={1} 
          clearcoatRoughness={0.25}/>
      </mesh>
      <mesh position={[(glassWidth / 2 + blackFrameSize + darkYellowFrameSize / 2), 0, 0]}>
        <boxGeometry args={[darkYellowFrameSize, height, frameThickness]} />
        <meshStandardMaterial color={frameColor}  side={DoubleSide}  clearcoat={1} 
          clearcoatRoughness={0.25}/>
      </mesh>
      <mesh position={[0, (glassHeight / 2 + blackFrameSize - darkYellowFrameSize / 2), 0]}>
        <boxGeometry args={[glassWidth + 2 * blackFrameSize, darkYellowFrameSize, frameThickness]} />
        <meshStandardMaterial color={frameColor}  side={DoubleSide}clearcoat={1} 
          clearcoatRoughness={0.25} />
      </mesh>

      {/* Green Frames */}
      <mesh position={[-(width / 2 - greenFrameSize / 2), 0, 0]}>
        <boxGeometry args={[greenFrameSize, height, greenFrameThickness]} />
        <meshStandardMaterial color={frameColor}  side={DoubleSide} clearcoat={1} 
          clearcoatRoughness={0.25} />
      </mesh>
      <mesh position={[(width / 2 - greenFrameSize / 2), 0, 0]}>
        <boxGeometry args={[greenFrameSize, height, greenFrameThickness]} />
        <meshStandardMaterial color={frameColor}  side={DoubleSide} clearcoat={1} 
          clearcoatRoughness={0.25} />
      </mesh>
      <mesh position={[0, (height / 2 - greenFrameSize / 2), 0]}>
        <boxGeometry args={[width, greenFrameSize, greenFrameThickness]} />
        <meshStandardMaterial color={frameColor}  side={DoubleSide}  clearcoat={1} 
          clearcoatRoughness={0.25}/>
      </mesh>






      

 



<>
    {hingePositions.map((position, index) => (
      <mesh key={`hinge-${index}`} position={position}>
        <cylinderGeometry args={[hingeRadius, hingeRadius, hingeHeight, 32]} />
        <meshStandardMaterial color={hingeColorMapping[color] || "#ffffff"} />
      </mesh>
    ))}
  </>



  






  {handle === "Lever Handle" && (
        <group position={[-0.12,0,0]}>
          <RoundedBox
            position={[handlePositionFront[0] + 0.125, handlePositionFront[1] + 0.03, handlePositionFront[2] - 0.04]}
            args={[0.08, 0.09, 0.02]}
            radius={0.01}
            smoothness={4}
          >
            <meshStandardMaterial color={frameColor} />
          </RoundedBox>


   
      <Cylinder args={[0.02, 0.02, 0.001, 32]} position={[handlePositionFront[0]+0.125, handlePositionFront[1]+0.03, handlePositionFront[2]-0.03]} rotation={[Math.PI / 2, 0, 0]}>
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

     
      <Cylinder args={[0.019, 0.019, 0.05, 32]} position={[handlePositionFront[0]+0.125, handlePositionFront[1]+0.03, handlePositionFront[2]-0.002]} rotation={[Math.PI / 2, 0, 0]}>
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

   
      <Cylinder args={[0.016, 0.016, 0.13, 32]} position={[handlePositionFront[0]+0.06, handlePositionFront[1]+0.03, handlePositionFront[2]+0.005]} rotation={[0, 0, -Math.PI / 2]}>
        <meshStandardMaterial color={frameColor} />
      </Cylinder>
      
  
      <RoundedBox position={[handlePositionFront[0] + 0.125, handlePositionFront[1]-0.1, handlePositionFront[2]-0.04]} args={[0.08, 0.09, 0.02]} radius={0.01} smoothness={4}>
        <meshStandardMaterial color={frameColor}  />
      </RoundedBox>


      <Cylinder args={[0.011, 0.011, 0.001, 32]} position={[handlePositionFront[0]+0.125, handlePositionFront[1]-0.09, handlePositionFront[2]-0.03]} rotation={[Math.PI / 2, 0, 0]}>
        <meshStandardMaterial color="#ffffff" />
      </Cylinder>


      <RoundedBox position={[handlePositionFront[0] + 0.125, handlePositionFront[1]-0.11, handlePositionFront[2]-0.038]} args={[0.015, 0.05, 0.02]} radius={0.007} smoothness={32}>
        <meshStandardMaterial color="#ffffff"  />
      </RoundedBox>

      {/* Handles (Back Side) */}
      {/* handle main box */}
      <RoundedBox position={[handlePositionFront[0] + 0.125, handlePositionFront[1]+0.03, handlePositionFront[2]-0.08]} args={[0.08, 0.09, 0.02]} radius={0.01} smoothness={4}>
        <meshStandardMaterial color={frameColor}  />
      </RoundedBox>

      {/* Main hole */}
      <Cylinder args={[0.02, 0.02, 0.001, 32]} position={[handlePositionFront[0]+0.125, handlePositionFront[1]+0.03, handlePositionFront[2]-0.09]} rotation={[Math.PI / 2, 0, 0]}>
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

      {/* Handele first part */}
      <Cylinder args={[0.019, 0.019, 0.05, 32]} position={[handlePositionFront[0]+0.125, handlePositionFront[1]+0.03, handlePositionFront[2]-0.11]} rotation={[Math.PI / 2, 0, 0]}>
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

      {/* Handele second part */}
      <Cylinder args={[0.016, 0.016, 0.13, 32]} position={[handlePositionFront[0]+0.06, handlePositionFront[1]+0.03, handlePositionFront[2]-0.115]} rotation={[0, 0, Math.PI / 2]}>
        <meshStandardMaterial color={frameColor} />
      </Cylinder>
      
      {/* key main box */}
      <RoundedBox position={[handlePositionFront[0] + 0.125, handlePositionFront[1]-0.1, handlePositionFront[2]-0.08]} args={[0.08, 0.09, 0.02]} radius={0.01} smoothness={4}>
        <meshStandardMaterial color={frameColor}  />
      </RoundedBox>

      {/* key hole */}
      <Cylinder args={[0.011, 0.011, 0.001, 32]} position={[handlePositionFront[0]+0.125, handlePositionFront[1]-0.09, handlePositionFront[2]-0.09]} rotation={[Math.PI / 2, 0, 0]}>
        <meshStandardMaterial color="#ffffff" />
      </Cylinder>

      {/* key hole down */}
      <RoundedBox position={[handlePositionFront[0] + 0.125, handlePositionFront[1]-0.11, handlePositionFront[2]-0.083]} args={[0.015, 0.05, 0.02]} radius={0.007} smoothness={32}>
        <meshStandardMaterial color="#ffffff"  />
      </RoundedBox>

      </group>
      )}
  







  {handle === "Offset Handle" && (
  <>
    {/* Front Handle */}
    <group position={[-0.12, 0.1, frameThickness / 2]}>
      {/* Horizontal Cylinder */}
      <Cylinder
        args={[0.019, 0.019, 0.09, 32]}
        position={[handlePositionFront[0] + 0.12, handlePositionFront[1] + 0.03, 0.05]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

      {/* Sphere Connector 1 */}
      <mesh position={[handlePositionFront[0] + 0.12, handlePositionFront[1] + 0.03, 0.09]}>
        <sphereGeometry args={[0.0195, 32, 32]} />
        <meshStandardMaterial color={frameColor} side={THREE.DoubleSide} />
      </mesh>

      {/* Vertical Cylinder Part (Connects to Frame) */}
      <Cylinder
        args={[0.016, 0.016, 0.1, 32]}
        position={[handlePositionFront[0] + 0.07, handlePositionFront[1] + 0.03, 0.0935]}
        rotation={[0, 0, -Math.PI / 2]}
      >
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

      {/* Sphere Connector 2 */}
      <mesh position={[handlePositionFront[0] + 0.018, handlePositionFront[1] + 0.03, 0.093]}>
        <sphereGeometry args={[0.0194, 32, 32]} />
        <meshStandardMaterial color={frameColor} side={THREE.DoubleSide} />
      </mesh>

      {/* Long Vertical Cylinder */}
      <Cylinder
        args={[0.019, 0.019, 0.25, 32]}
        position={[handlePositionFront[0] + 0.016, handlePositionFront[1] - 0.09, 0.093]}
        rotation={[0, 0, 0]}
      >
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

      {/* Sphere Connector 3 */}
      <mesh position={[handlePositionFront[0] + 0.018, handlePositionFront[1] - 0.225, 0.093]}>
        <sphereGeometry args={[0.0187, 32, 32]} />
        <meshStandardMaterial color={frameColor} side={THREE.DoubleSide} />
      </mesh>

      {/* Short Horizontal Cylinder (Connects to Frame) */}
      <Cylinder
        args={[0.016, 0.016, 0.1, 32]}
        position={[handlePositionFront[0] + 0.06, handlePositionFront[1] - 0.229, 0.0935]}
        rotation={[0, 0, Math.PI / 2]}
      >
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

      {/* Sphere Connector 4 */}
      <mesh position={[handlePositionFront[0] + 0.12, handlePositionFront[1] - 0.23, 0.09]}>
        <sphereGeometry args={[0.0195, 32, 32]} />
        <meshStandardMaterial color={frameColor} side={THREE.DoubleSide} />
      </mesh>

      {/* Bottom Horizontal Cylinder */}
      <Cylinder
        args={[0.019, 0.019, 0.09, 32]}
        position={[handlePositionFront[0] + 0.12, handlePositionFront[1] - 0.23, 0.05]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

      <group position={[0, 0, 0]}>
        {/* key main box */}
        <RoundedBox position={[handlePositionFront[0] + 0.125, handlePositionFront[1]-0.1, handlePositionFront[2]-0.04]} args={[0.08, 0.09, 0.02]} radius={0.01} smoothness={4}>
          <meshStandardMaterial color={frameColor} />
        </RoundedBox>

        {/* key hole */}
        <Cylinder args={[0.011, 0.011, 0.001, 32]} position={[handlePositionFront[0]+0.125, handlePositionFront[1]-0.09, handlePositionFront[2]-0.03]} rotation={[Math.PI / 2, 0, 0]}>
          <meshStandardMaterial color="white"/>
        </Cylinder>

        {/* key hole down */}
        <RoundedBox position={[handlePositionFront[0] + 0.125, handlePositionFront[1]-0.11, handlePositionFront[2]-0.038]} args={[0.015, 0.05, 0.02]} radius={0.007} smoothness={32}>
          <meshStandardMaterial color="white" />
        </RoundedBox>
      </group>

    </group>

    {/* Backside Handle */}
    <group position={[-0.12, 0.1, -frameThickness / 2]}>
      {/* Horizontal Cylinder */}
      <Cylinder
        args={[0.019, 0.019, 0.09, 32]}
        position={[handlePositionFront[0] + 0.12, handlePositionFront[1] + 0.03, -0.05]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

      {/* Sphere Connector 1 */}
      <mesh position={[handlePositionFront[0] + 0.12, handlePositionFront[1] + 0.03, -0.09]}>
        <sphereGeometry args={[0.0195, 32, 32]} />
        <meshStandardMaterial color={frameColor} side={THREE.DoubleSide} />
      </mesh>

      {/* Vertical Cylinder Part (Connects to Frame) */}
      <Cylinder
        args={[0.016, 0.016, 0.1, 32]}
        position={[handlePositionFront[0] + 0.08, handlePositionFront[1] + 0.03, -0.0935]}
        rotation={[0, 0, Math.PI / 2]}
      >
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

      {/* Sphere Connector 2 */}
      <mesh position={[handlePositionFront[0] + 0.022, handlePositionFront[1] + 0.03, -0.093]}>
        <sphereGeometry args={[0.0194, 32, 32]} />
        <meshStandardMaterial color={frameColor} side={THREE.DoubleSide} />
      </mesh>

      {/* Long Vertical Cylinder */}
      <Cylinder
        args={[0.019, 0.019, 0.25, 32]}
        position={[handlePositionFront[0] + 0.021, handlePositionFront[1] - 0.09, -0.093]}
        rotation={[0, 0, 0]}
      >
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

      {/* Sphere Connector 3 */}
      <mesh position={[handlePositionFront[0] + 0.022, handlePositionFront[1] - 0.225, -0.093]}>
        <sphereGeometry args={[0.0187, 32, 32]} />
        <meshStandardMaterial color={frameColor} side={THREE.DoubleSide} />
      </mesh>

      {/* Short Horizontal Cylinder (Connects to Frame) */}
      <Cylinder
        args={[0.016, 0.016, 0.1, 32]}
        position={[handlePositionFront[0] + 0.08, handlePositionFront[1] - 0.229, -0.0935]}
        rotation={[0, 0, Math.PI / 2]}
      >
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

      {/* Sphere Connector 4 */}
      <mesh position={[handlePositionFront[0] + 0.12, handlePositionFront[1] - 0.23, -0.09]}>
        <sphereGeometry args={[0.0195, 32, 32]} />
        <meshStandardMaterial color={frameColor} side={THREE.DoubleSide} />
      </mesh>

      {/* Bottom Horizontal Cylinder */}
      <Cylinder
        args={[0.019, 0.019, 0.09, 32]}
        position={[handlePositionFront[0] + 0.12, handlePositionFront[1] - 0.23, -0.05]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial color={frameColor} />
      </Cylinder>

      <group position={[0, 0, 0]}>
        {/* key main box */}
        <RoundedBox position={[handlePositionFront[0] + 0.125, handlePositionFront[1]-0.1, handlePositionFront[2]-0.08]} args={[0.08, 0.09, 0.02]} radius={0.01} smoothness={4}>
          <meshStandardMaterial color={frameColor} />
        </RoundedBox>

        {/* key hole */}
        <Cylinder args={[0.011, 0.011, 0.001, 32]} position={[handlePositionFront[0]+0.125, handlePositionFront[1]-0.09, handlePositionFront[2]-0.09]} rotation={[Math.PI / 2, 0, 0]}>
          <meshStandardMaterial color="white"/>
        </Cylinder>

        {/* key hole down */}
        <RoundedBox position={[handlePositionFront[0] + 0.125, handlePositionFront[1]-0.11, handlePositionFront[2]-0.083]} args={[0.015, 0.05, 0.02]} radius={0.007} smoothness={32}>
          <meshStandardMaterial color="white" />
        </RoundedBox>
      </group>

    </group>
  </>
)}




















{closer === "Exposed Closer" && (
  <>
    <group position={[dynamicDoorCloserX, dynamicDoorCloserY, dynamicDoorCloserZ]} rotation={[0, 0, 0]}>

      {/* Main Box */}
      <mesh position={[0, 0.01, 0.001]}>
        <boxGeometry args={[0.20, 0.05, 0.05]} />
        <meshStandardMaterial color={closerColor} />
      </mesh>

      {/* Cylinder */}
      <mesh position={[-0.05, 0.04, 0]}>
        <cylinderGeometry args={[0.02, 0.005, 0.03, 100]} />
        <meshStandardMaterial color={closerColor} />
      </mesh>

      {/* Connector */}
      <mesh position={[0.07, 0.08, 0.001]}>
        <boxGeometry args={[0.30, 0.03, 0.05]} />
        <meshStandardMaterial color={closerColor} />
      </mesh>

      {/* Connector */}
      <mesh position={[0.07, 0.05, 0.001]}>
        <boxGeometry args={[0.27, 0.01, 0.05]} />
        <meshStandardMaterial color={closerColor} />
      </mesh>

      {/* Cylinder */}
      <mesh position={[0.18, 0.06, 0]}>
        <cylinderGeometry args={[0.01, 0.005, 0.03, 100]} />
        <meshStandardMaterial color={closerColor} />
      </mesh>
    </group>
  </>
)}








  



    
    </group>
  );
};

export default PullLeftDoor;