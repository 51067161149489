import React from 'react';
import './products.css'; // Assuming you have a CSS file for custom styles
import { Container, Paper, Typography, Button,Box } from '@mui/material';
import Inprogress from '../assets/inprogress.png';

const Products = () => {
    return (
        <Container id="windows-screen" maxWidth="sm">
            <div className='upload-desc'>
            <Typography className="poppins-semiBold" gutterBottom>
                    Start new windows design
                </Typography>
                <Typography className="roboto-font" gutterBottom>
                    Create your project from a blank plan or shape and give free rein to your imagination.
                </Typography>
            </div>
           
            <Paper elevation={2} style={{ padding: '20px', textAlign: 'center',width:'383px',height:'211px',marginLeft:'15%',border: '1px dashed #757575' }} >
            <Box
      component="img"
      className='upload-img'
      sx={{ flexGrow: 0 }}
      alt="Image 6"
      src={Inprogress}
    />
            </Paper>
            <div> 
                </div>
        </Container>
    );
};

export default Products;
