import React from "react";
import { Box } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/home/home";
import MiniDrawer from "./header";
import DrawingApp from "./components/design-screen/drawingApp";
import Examples from "./pages/examples";
import { RecoilRoot } from "recoil";

import Viewer from "./components/design-screen/3D/index";
import UploadScreen from "./components/upload-screen/uploadScreen";
import { FlagProvider } from "./flag-context";
import ProjectForm from "./components/home/project-form";
import Products from "./components/products";
import Orders from "./components/Orders/Orders";
import { ProjectProvider } from "./components/home/ProjectContext";
import { CustomerProvider } from "./context/CustomerContext";
import { UserProvider } from "./context/UserContext";
import { BusinessProvider } from "./context/BusinessContext";

function AdminApp() {
  return (
    <iframe
      src="/admin/index.html"
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title="Admin App"
    />
  );
}

function App() {
  return (
    <UserProvider>
      <CustomerProvider>
        <BusinessProvider>
          <FlagProvider>
            <ProjectProvider>
              <Router>
                <div style={{ display: "flex", width: "100%" }}>
                  <MiniDrawer />
                  <main style={{ flexGrow: 1, padding: "16px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "90vh",
                        float: "left",
                      }}
                    >
                      <RecoilRoot>
                        <Routes>
                          <Route path="/" element={<HomePage />} />
                          <Route path="/" element={<Examples />}>
                            <Route path=":id" element={<Examples />} />
                          </Route>
                          <Route path="/draw" element={<UploadScreen />} />
                          <Route path="/home" element={<HomePage />} />
                          <Route path="/designWithoutBg" element={<DrawingApp />} />
                          <Route path="/3dViewer" element={<Viewer />} />
                          <Route path="/project" element={<ProjectForm />} />
                          <Route path="/Orders" element={<Orders />} />
                          <Route path="/windows" element={<Products />} />
                          <Route path="*" element={<div>Page Not found.</div>} />
                          <Route path="/admin/*" element={<AdminApp />} />
                        </Routes>
                      </RecoilRoot>
                    </Box>
                  </main>
                </div>
              </Router>
            </ProjectProvider>
          </FlagProvider>
        </BusinessProvider>
      </CustomerProvider>
    </UserProvider>
  );
}

export default App;
