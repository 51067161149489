import React, { createContext, useState, useEffect } from 'react';
 
export const UserContext = createContext();
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
 
export const UserProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
 
  const fetchUsers = async () => {
    setLoading(true);
    setError(null);
 
    try {
      const response = await fetch(`${BASE_URL}/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      console.log(data);
      if (data.status === 'success') {
        setUsers(data.data);
      } else {
        setError('Failed to retrieve Users.');
      }
    } catch (err) {
      setError('An error occurred while fetching Users.');
    } finally {
      setLoading(false);
    }
  };
 
  useEffect(() => {
    fetchUsers();
  }, []);
 
 
  return (
    <UserContext.Provider
      value={{
        users,
        loading,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
