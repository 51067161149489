import React from 'react';
import { DoubleSide, FrontSide } from 'three';
import * as THREE from 'three';


const PartitionSegment = ({
  x1, y1, x2, y2, panelHeight, offsetX, offsetY, polycarbonateShape, profileFinish
}) => {
  // Convert panel height to meters, subtracting 40mm for the top and bottom frame thickness
  const panelHeightInMeters = (panelHeight - 40 -20) / 999;

  const position = [
    (x1 + x2) / 2 / 100 - offsetX,
    panelHeight / 2 / 1000,
    (y1 + y2) / 2 / 100 - offsetY,
  ];

  const totalLength = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)) / 100;
  const colorMap = {
    "Black Matt": "black",
    "Natural Anodized": "grey",
    "Champagne": "gold",
    "Rose Gold": "#B76E79",
    "Custom": "purple",
  };

  const frameColor = colorMap[profileFinish] || "grey";
  const verticalFrameThickness = polycarbonateShape === "Mullion" ? 0.02 : 0.002;
  const horizontalFrameThickness = 0.04; // Top and bottom frame thickness is 40mm (0.04m)
  const verticalFrameColor = polycarbonateShape === "Mullion" ? frameColor : "white";
  const frameDepth = 0.02;
  const angle = Math.atan2(y2 - y1, x2 - x1);

  const generateGlassColor = () => {
    const colors = ['#6F686D'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const glassColor = generateGlassColor();



  return (
    <group position={position} rotation={[0, -angle, 0]}>
      {/* Inner Partition Frame (Glass) */}
      <mesh position={[0, 0, 0]}>
        <boxGeometry args={[totalLength - verticalFrameThickness * 2, panelHeightInMeters, 0.01]} />
        <meshPhysicalMaterial
          color={glassColor}
          transparent
          opacity={0.7}
          roughness={0.3}
          metalness={0.15}
          side={THREE.FrontSide}
        />
      </mesh>

      {/* Left Vertical Frame */}
      <mesh position={[-totalLength / 2 + verticalFrameThickness / 2, 0, 0.005]}>
        <boxGeometry args={[verticalFrameThickness, panelHeightInMeters + 0.04, frameDepth]} />
        <meshStandardMaterial color={verticalFrameColor} side={DoubleSide} />
      </mesh>

      {/* Right Vertical Frame */}
      <mesh position={[totalLength / 2 - verticalFrameThickness / 2, 0, 0.005]}>
        <boxGeometry args={[verticalFrameThickness, panelHeightInMeters + 0.04, frameDepth]} />
        <meshStandardMaterial color={verticalFrameColor} side={DoubleSide} />
      </mesh>

      {/* Top Horizontal Frame */}
      <mesh position={[0, (panelHeightInMeters + 0.02) / 2, 0.005]}>
        <boxGeometry args={[totalLength, horizontalFrameThickness, frameDepth]} />
        <meshStandardMaterial color={frameColor} side={DoubleSide} />
      </mesh>

      {/* Bottom Horizontal Frame */}
      <mesh position={[0, -(panelHeightInMeters + 0.02) / 2, 0.005]}>
        <boxGeometry args={[totalLength, horizontalFrameThickness, frameDepth]} />
        <meshStandardMaterial color={frameColor} side={DoubleSide} />
      </mesh>

      {/* Additional elements for Two-Way Polycarbonate */}
      {polycarbonateShape === "Two-Way Polycarbonate" && (
        <>
          {/* Start Purple Vertical Frame */}
          <mesh position={[-totalLength / 2 - 0.1, 0, 0.01]}>
            <boxGeometry args={[0.05, panelHeightInMeters, 0.03]} />
            <meshStandardMaterial color="purple" side={DoubleSide} />
          </mesh>

          {/* End Purple Vertical Frame */}
          <mesh position={[totalLength / 2 + 0.1, 0, 0.01]}>
            <boxGeometry args={[0.05, panelHeightInMeters, 0.03]} />
            <meshStandardMaterial color="purple" side={DoubleSide} />
          </mesh>
        </>
      )}
    </group>
  );
};

export default PartitionSegment;
