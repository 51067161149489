import React, { useState, useContext, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Tooltip from "@mui/material/Tooltip";

import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import StraightenIcon from "@mui/icons-material/Straighten";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import homeIcon from "../assets/homeIcon.jpg";
import { useNavigate } from "react-router-dom"; // <-- Import useNavigate
import { ProjectContext } from "./home/ProjectContext";
import { MenuItem, TextField } from "@mui/material";
import DownArrow from "../assets/downArrow.png";
import { Grid, Paper, Button, Divider } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Import for the icon
import { getRuleByName, getConstants } from '../service/rules.service';
import ImageListItemBar from "@mui/material/ImageListItemBar";
import StickOn from "../assets/stick-on.png";
import Jcap from "../assets/Jcap.png";
import Fixed from "../assets/polycarbonate.png";
import Expand from "../assets/expand.png";
import Partition_dark from "../assets/Partition_dark.png";
import Door_dark from "../assets/Door_dark.png";
import Partition_lite from "../assets/Partition_lite.png";
import Door_lite from "../assets/Door_lite.png";
import Stile from "../assets/stile.png";
import Sliding from "../assets/sliding.png";
import Patch_fitting from "../assets/patch_fitting.png";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/system";
import GridOnIcon from "@mui/icons-material/GridOn";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import FlagContext from "../flag-context";
import Properties from "./Properties";
import { useLocation } from "react-router-dom"; // <-- Added for project and design name integration

const Templates = () => {
  const [value, setValue] = useState(0);
  const [expandValue, setExpandValue] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [results, setResults] = useState({});
  const [selected, setSelected] = useState("");
  const [tabValue, setTabValue] = useState("");
  const location = useLocation(); // <-- Added for project and design name integration
  const { systemId, productId } = useContext(ProjectContext);  // or get from state

  const BASE_URL = process.env.REACT_APP_BASE_API_URL;

  const navigate = useNavigate(); // <-- Define navigate using useNavigate

  // Get flag from context
  const { flag, updateFlag, resetFlag } = useContext(FlagContext);  // <-- Remove the useState declaration here
  const { fetchDesignByProjectId, designs } = useContext(ProjectContext);
  const [selectedDesign, setSelectedDesign] = useState(flag.TwoD.designID || "");
  const projectName = flag.TwoD.projectName;

  useEffect(() => {
    console.log(projectName, "projectName");
    console.log(designs, "designs");
    console.log(selectedDesign, "designID");
  }, []);

  const handleDesignChange = async (event) => {
    const newDesignName = event.target.value;
    const designId = event.target.value;

    setSelectedDesign(newDesignName);
    updateFlag("TwoD", { designID: designId });

    try {
      const response = await fetch(`${BASE_URL}/designs/${designId}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch design data");
      }

      const data = await response.json();
      const savedData = data.data.design_attributes?.Data2D || [];

      updateFlag("Data2D", savedData); // Load saved 2D data for the specific design
    } catch (error) {
      console.error("Error fetching design data:", error);
      updateFlag("Data2D", []); // Reset if fetching fails
    }

    navigate("/designWithoutBg", {
      state: { designName: newDesignName },
    });
  };

  const handleSelection = (event, newSelection) => {
    const [system_id, index] = event.target.id.split("|");
    updateFlag("TwoD", {
      division: newSelection,
      system_id: system_id,
      divisionIndex: Number(index),
      mode: "",
      modeIndex: null,
    });
  };

  const toggleTabs = () => {
    updateFlag("ExpandProperties", !flag.ExpandProperties);
  };

  const handleTabChange = (event, newTabValue) => {
    const [product_id, index] = event.target.id.split("|");

    // Check if the new value is different from the current mode, and not null or undefined
    if (newTabValue !== flag.TwoD.mode && newTabValue !== null && newTabValue !== undefined) {
      // Update the flag context with the new mode and reset type, along with other properties
      updateFlag({
        TwoD: {
          mode: newTabValue,
          modeIndex: Number(index),
          product_id: product_id,
          type: "",
          typeIndex: null,
        },
        isSelected: false,
        ExpandProperties: true,
      });
    }
  };

  const handleTypeSelect = (type, index) => {
    updateFlag("TwoD", { type: type, typeIndex: Number(index) });
  };
  const itemData = {
    Partition: [
      {
        img: Fixed,
        title: "Fixed Partition",
    
      },
      {
        img: Jcap,
        title: "Partition with Jcap",
      },
      {
        img: StickOn,
        title: "Partition with stick-on profile",
      },
    ],
    Door: [
      {
        img: Stile,
        title: "Stile Door - Single leaf",
      },
      {
        img: Sliding,
        title: "Sliding Door",
      },
      {
        img: Patch_fitting,
        title: "Patch fitting Door",
      },
    ],
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    border: "none",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  }));

  const toggleGrid = () => {
    console.log(flag.TwoD);
    updateFlag("TwoD", { gridVisible: !flag.TwoD.gridVisible });
  };

  const toggleUnit = () => {
    updateFlag("TwoD", { unit: flag.TwoD.unit === "mm" ? "Inches" : "mm" });
  };

  // Helper function to calculate distance between two points
  const calculateDistance = (x1, y1, x2, y2) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  };

  const undo = () => {
    if (flag.TwoD.history.length > 1) {
      const newHistory = [...flag.TwoD.history];
      const previousState = newHistory.pop();
      updateFlag("TwoD", {
        redoStack: [previousState, ...flag.TwoD.redoStack],
      });
      updateFlag("Data2D", newHistory[newHistory.length - 1]);
      updateFlag("TwoD", { history: newHistory });
      updateFlag("TwoD", { isRedoDisabled: false });

      if (newHistory.length === 1) {
        updateFlag("TwoD", { isUndoDisabled: true }); // Disable Undo button if there's only one history entry left
      }
    }
  };

  const redo = () => {
    if (flag.TwoD.redoStack.length > 0) {
      const newRedoStack = [...flag.TwoD.redoStack];
      const redoState = newRedoStack.shift();
      updateFlag("TwoD", { history: [...flag.TwoD.history, redoState] });
      updateFlag("Data2D", redoState);
      updateFlag("TwoD", { redoStack: newRedoStack });
      updateFlag("TwoD", { isUndoDisabled: false }); // Enable Undo button

      if (newRedoStack.length === 0) {
        updateFlag("TwoD", { isRedoDisabled: true });
      }
    }
  };

  const DoorDir = [
    {
      Orientation: "Left",
      Opening: "Push",
      doordirection: 0,
    },
    {
      Orientation: "Right",
      Opening: "Push",
      doordirection: 1,
    },
    {
      Orientation: "Left",
      Opening: "Pull",
      doordirection: 2,
    },
    {
      Orientation: "Right",
      Opening: "Pull",
      doordirection: 3,
    },
  ];

  const changeDirection = () => {
    updateFlag(
      "Data2D",
      flag.Data2D.map((line) => {
        if (line.id === flag.isSelected) {
          let doordirection = (line.doordirection + 1) % 4;
          return {
            ...line,
            doordirection: doordirection,
            Opening: DoorDir[doordirection].Opening,
            Orientation: DoorDir[doordirection].Orientation,
          };
        } else return { ...line };
      })
    );
  };

  const deleteItem = () => {
    updateFlag(
      "Data2D",
      flag.Data2D.filter((line) => line.id !== flag.isSelected)
    );
    updateFlag("isSelected", null);
  };

  const defaultPanelLength = 1500;

  const calculatePanels = (totalLength) => {
    return Math.ceil(totalLength / defaultPanelLength);
  };
  const calculateBOM = () => {
    const materials = { ...flag.materials };
    const profile_constant = 30;
    const profileLength = 3000; // Length of one profile

    flag.Data2D.forEach((item) => {
      if (item.mode === "Partition") {
        const partitionId = item.id; // Unique partition identifier
        const panelHeight = parseInt(item["Partition Height"], 10);
        const ruleNames = {
          glassHeight: "Glass Height",
          profileWidth: "Profile width for fixed partition",
          screwsQty: "Screws",
          gasketQty: "Gasket",
        };
        const constants = getConstants(flag.Rules.data); // Extract constants from API
        const computedResults = {};
        const [x1, y1, x2, y2] = item.points;
        const partitionWidth = calculateDistance(x1, y1, x2, y2) * 10;

        const profileWidth = partitionWidth - (2 * profile_constant);
        const totalTopLeftLength = profileWidth + panelHeight;
        const totalBottomRightLength = profileWidth + panelHeight;
        const numberOfProfilesTopLeft = Math.ceil(totalTopLeftLength / profileLength);
        const numberOfProfilesBottomRight = Math.ceil(totalBottomRightLength / profileLength);
        const totalProfiles = numberOfProfilesTopLeft + numberOfProfilesBottomRight;
        const segmentWidths = item.segments.map((seg) => Math.round(seg.adjustedLength));
        const polycarbonateQty = item.segments.length - 1;
        Object.entries(ruleNames).forEach(([key, ruleName]) => {
          const rule = getRuleByName(flag.Rules.data, ruleName);
          if (rule.rule_expression) {
            const formula = rule.rule_expression
              .replace(/panelHeight/g, panelHeight)
              .replace(/partitionWidth/g, partitionWidth)
              .replace(/profile_width/g, constants.profile_width || 0)
              .replace(/numberOfProfilesTopLeft/g, numberOfProfilesTopLeft)
              .replace(/numberOfProfilesBottomRight/g, numberOfProfilesBottomRight)
              .replace(/screw_quantity/g, constants.screw_quantity || 10)
              .replace(/glass_insertion/g, constants.glass_insertion)
              .replace(/profile_sides/g, constants.profile_sides)
              .replace(/totalProfileLength/g, totalProfiles)
              .replace(/totalProfileQuantity/g, totalProfiles)
              .replace(/openingWidth/g, partitionWidth);
            computedResults[key] = eval(formula); // Safely evaluate formula
          }
        });
        setResults(computedResults);
        const groupedDimensions = {};
        segmentWidths.forEach((width) => {
          const dimensionLabel = ` ${width} x ${computedResults.glassHeight}`;
          groupedDimensions[dimensionLabel] = (groupedDimensions[dimensionLabel] || 0) + 1;
        });

        // Prepare the materials data for this partition
        const newPartitionMaterials = {
          Glass: Object.entries(groupedDimensions).map(([dimension, quantity]) => ({
            dimensions: dimension,
            quantity,
          })),
          Glass_Joint: [{ dimensions: `H x ${panelHeight}`, quantity: polycarbonateQty }],
          U_Profile: [{ dimensions: `H x ${panelHeight}`, quantity: numberOfProfilesTopLeft }],
          ClipOnBase_Profile: [{ dimensions: `H x ${panelHeight}`, quantity: numberOfProfilesBottomRight }],
          ClipOnClip_Profile: [{ dimensions: `H x ${panelHeight}`, quantity: numberOfProfilesBottomRight }],
          Gasket: [{ dimensions: `H x ${panelHeight}`, quantity: computedResults.gasketQty }],
          Accessories: [{ screwsQty: computedResults.screwsQty, plasticAnchorQty: computedResults.screwsQty }],
        };

        // Check if the partition already exists and if the data is different
        if (
          !materials[partitionId] ||
          JSON.stringify(materials[partitionId]) !== JSON.stringify(newPartitionMaterials)
        ) {
          materials[partitionId] = newPartitionMaterials;
        }
      }
    });

    updateFlag("materials", materials);
  };


  const onSave = async () => {
    let finalData = flag.Data2D.map((line) => {
      const [x1, y1, x2, y2] = line.points;
      return {
        ...line,
        totalLength: calculateDistance(x1, y1, x2, y2) * 10,
        noOfPannels: line.mode === "Partition" ? calculatePanels(calculateDistance(x1, y1, x2, y2) * 10) : undefined,
      };
    });

    updateFlag("Data2D", finalData);
    calculateBOM();
    const designId = flag.TwoD.designID
    const requestData = {
      system_id: flag.TwoD.system_id,
      product_id: flag.TwoD.product_id,
      user_id: "1",
      design_attributes: { "Data2D": finalData },
    };

    try {
      const response = await fetch(`${BASE_URL}/designs/${designId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) throw new Error("Failed to save data");
      setSnackbarOpen(true); // Open snackbar on success
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };


  return (
    <Box sx={{ width: "100%", "& .MuiToolbar-root": { minHeight: "45px" } }}>
   <AppBar
  position="static"
  sx={{
    boxShadow: "none",
    backgroundColor: "#fff",
    borderRadius: "10px",
    width: "100%",
    height: "35px",  // Adjust height if needed
    marginBottom: "0", // Remove extra space after AppBar
    paddingBottom: "0", // Ensure no extra padding below
  }}
>
<Toolbar sx={{ height: "35px !important", backgroundColor: "#fff", paddingBottom: "0px" }}>          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#fff", // White background for project section
              padding: "0px 10px",
              borderRadius: "4px",
              flexGrow: 1,
              minHeight: "30px !important",
              height: "30px !important",

            }}
          >
            <Box
              component="img"
              className="home Icon"
              sx={{
                flexGrow: 0,
                width: "24px",
                height: "24px",
                marginRight: 1,
                marginLeft: "-30px", // Adjust this value to move it left
              }}
              alt="home Icon"
              src={homeIcon}
            />
              <Tooltip
  title={projectName}
  arrow
  PopperProps={{
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10], // Adjust the vertical offset (negative value raises the tooltip)
        },
      },
    ],
  }}
>
  <Typography
    sx={{
      flexGrow: 0,
      color: "#000",
      fontFamily: "Roboto",
      fontSize: "small",
      fontWeight: "400",
      marginRight: "8px",
      whiteSpace: "nowrap", // Prevent text from wrapping
      overflow: "hidden", // Hide overflow text
      textOverflow: "ellipsis", // Show ellipsis when text overflows
      width: "100px", // Set the width to limit text display
    }}
  >
    Project: {projectName || "Select Project"}
  </Typography>
</Tooltip>
            <TextField
              select
              value={selectedDesign}
              onChange={handleDesignChange}
              sx={{
                minWidth: 150,
                bgcolor: "#E0E0E0", // Cement color for design dropdown
                borderRadius: "4px",
                fontFamily: "Roboto",
                fontSize: "small", // Set font size to match project text
                fontWeight: "400",
                color: "#000",
                "& .MuiSelect-select": {
                  padding: "6px 8px", // Adjusted padding for vertical centering
                  bgcolor: "#E0E0E0", // Match cement color for inner dropdown
                  borderRadius: "4px",
                  height: "20px", // Adjusted height
                  display: "flex",
                  alignItems: "center", // Center content vertically
                  fontSize: "small", // Set font size here as well for inner content
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSelect-icon": {
                  right: "8px",
                },
              }}
            >
              {designs.map((design) => (
                <MenuItem key={design.design_id} value={design.design_id}>
                  {design.design_name}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box
            sx={{
              borderRadius: "25px",
              flexGrow: 0,
              marginLeft: "5px",
              bgcolor: "#F6F7F9 !important",
              marginTop: "5px",
              marginBottom: "5px",
              height: "35px",
            }}
          >
            <ToggleButtonGroup
              value={flag.TwoD.division}
              exclusive
              onChange={handleSelection}
              aria-label="text alignment"
            >
              {flag.Properties.systems && flag.Properties.systems.map((each, index) => {
                return (
                  <ToggleButton
                    sx={{
                      border: "none",
                      borderRadius: "25px",
                      color: "#191919",
                      fontFamily: "Roboto",
                      fontSize: "small",
                      height: "35px",
                      textTransform: "capitalize",
                      "&.Mui-selected": {
                        backgroundColor: "#5E8DE9",
                        borderRadius: "25px",
                        color: "white",
                        height: "35px",
                        "&:hover": {
                          backgroundColor: "#5E8DE9",
                        },
                      },
                    }}
                    value={each.system_name}
                    id={`${each.system_id}|${index}`}
                  >
                    {each.system_name}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Box>

          <Tooltip title="Zoom In" arrow>
            <IconButton color="black" sx={{ padding: "4px" }} aria-label="zoom in">
              <ZoomInIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Zoom Out" arrow>
            <IconButton color="black" sx={{ padding: "4px" }} aria-label="zoom out">
              <ZoomOutIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Fullscreen" arrow>
            <IconButton color="black" aria-label="fullscreen">
              <FullscreenIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Grid" arrow>
            <IconButton color="black" aria-label="grid" onClick={toggleGrid}>
              <GridOnIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Measure" arrow>
            <IconButton color="black" aria-label="measure" onClick={toggleUnit}>
              <StraightenIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Undo" arrow>
            <IconButton color="black" size="small" aria-label="undo" onClick={undo}>
              <UndoIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Redo" arrow>
            <IconButton color="black" size="small" aria-label="redo" onClick={redo}>
              <RedoIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete" arrow>
            <IconButton color="black" size="small" aria-label="delete" onClick={deleteItem}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>

          <Button
      type="submit"
      onClick={onSave}
      style={{
        color: "#ffff",
        backgroundColor: "#214386",
        fontSize: "small",
        marginLeft: "12px",
        marginRight: "-14px",
        textTransform: "capitalize",
        marginBottom: "0px", // Ensure no extra space at the bottom
      }}
      className="roboto-font small-font text-lower"
      variant="contained"
    >
      Save
    </Button>
        </Toolbar>
      </AppBar>

      {flag.TwoD.division !== "" && (
        <Box sx={{ width: "100%", marginTop: 2 }}>
          <AppBar
            sx={{
              width: "31.8%", // Adjust this if needed to make it narrower
              backgroundColor: "#fff",
              borderRadius: "5px",
              padding: "5px", // Reduce padding if there is extra space
              margin: "0", // Ensure there's no extra margin
            }}
            position="static"
          >
            <Box
              sx={{
                borderRadius: "5px",
                flexGrow: 0,
                display: "flex",
                marginLeft: "10px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
             <ToggleButtonGroup
      value={flag.TwoD.mode}
      exclusive
      onChange={handleTabChange}
      aria-label="text alignment"
      sx={{ flexGrow: 1, display: "flex" }}
    >
      {flag.Properties.systems[flag.TwoD.divisionIndex].products.map((each, index) => {
        let name = each.product_name;
        return (
          <ToggleButton
            sx={{
              border: "none",
              borderRadius: "5px",
              borderTopRightRadius: name === "Partition" ? "0px" : "5px",
              borderBottomRightRadius: name === "Partition" ? "0px" : "5px",
              borderTopLeftRadius: name === "Partition" ? "5px" : "0px",
              borderBottomLeftRadius: name === "Partition" ? "5px" : "0px",
              color: "#191919",
              height: "28px",
              fontFamily: "Roboto",
              fontSize: "small",
              textTransform: "capitalize",
              "&.Mui-selected": {
                backgroundColor: "#5E8DE9",
                borderRadius: "5px",
                borderTopRightRadius: name === "Partition" ? "0px" : "5px",
                borderBottomRightRadius: name === "Partition" ? "0px" : "5px",
                borderTopLeftRadius: name === "Partition" ? "5px" : "0px",
                borderBottomLeftRadius: name === "Partition" ? "5px" : "0px",
                height: "28px",
                color: "white",
                "&:hover": {
                  backgroundColor: "#5E8DE9",
                },
              },
            }}
            value={name}
            id={`${each.product_id}|${index}`}
            aria-label={name}
            onClick={name === "Partition" ? toggleTabs : undefined}
          >
            <img
              id={`${each.product_id}|${index}`}
              src={
                name === "Partition"
                  ? flag.TwoD.mode === "Partition"
                    ? Partition_lite
                    : Partition_dark
                  : name === "Door"
                    ? flag.TwoD.mode === "Door"
                      ? Door_lite
                      : Door_dark
                    : null
              }
              alt={`${name} icon`}
              style={{ width: "16px", height: "16px", margin: "10px" }}
            />
            {name}
          </ToggleButton>
        );
      })}
                <ToggleButton sx={{ border: "none", flexGrow: 1 }}>
                  <Box
                    component="img"
                    className="home-img"
                    sx={{
                      width: "16px",
                      height: "16px",
                      position: "absolute",
                      marginLeft: "1px",
                    }}
                    alt="Down Arrow"
                    src={Expand}
                    onClick={toggleTabs}
                  />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            {/* next */}
            {flag.ExpandProperties && flag.TwoD.mode && !flag.TwoD.type && (
              <div>
                <Divider
                  sx={{ borderWidth: "thin", width: "95%", marginLeft: "2.5%" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "small",
                    color: "black",
                    padding: "0px",
                    marginTop: "1%",
                    marginLeft: "5%",
                    marginBottom: "-3%",
                  }}
                >
                  Templates selection
                </Typography>
                {flag.TwoD.mode && (
                  <TabPanel>
                    <Grid container spacing={0} direction="column">
                    <ImageList
  sx={{
    width: 260,
    height: 500,
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: '400px',
    mt: 0.5, // Margin to separate from the previous section
    padding: "0",
    margin: "-13px", // Reduce margin here
    scrollbarWidth: "thick",
    paddingRight: "20px", // Add padding to move the scrollbar left
    '::-webkit-scrollbar': {
      width: '8px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#5E8DE9',
      borderRadius: '5px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#5E8DE9',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '5px',
    },
  }}
  cols={1}
>
            {flag.Properties.systems && flag.Properties.systems[
                          flag.TwoD.divisionIndex
                        ].products[flag.TwoD.modeIndex].templates.map(
                          (each, index) => {
                            let item = each.template_name;
                            let imageSrc;
                            if (item === "Fixed Partition") {
                              imageSrc = Fixed;
                            } else if (item === "Partition with Jcap") {
                              imageSrc = Jcap;
                            } else if (
                              item === "Partition with stick-on profile"
                            ) {
                              imageSrc = StickOn;
                            } else if (item === "Stile Door - Single leaf") {
                              imageSrc = Stile;
                            } else if (item === "Sliding Door") {
                              imageSrc = Sliding;
                            } else if (item === "Patch Fitting Door") {
                              imageSrc = Patch_fitting;
                            }
                            return (
                              <ImageListItem key={imageSrc}>
                                <img
                                  srcSet={`${imageSrc}?w=148&fit=crop&auto=format&dpr=2 2x`}
                                  src={`${imageSrc}?w=148&fit=crop&auto=format`}
                                  alt={item}
                                  style={{
                                    borderRadius: "10px",
                                    ...(flag.TwoD.mode == "Partition"
                                      ? {
                                        objectFit: "cover",
                                        height: "100px",
                                        marginLeft:"1px",
                                      }
                                      : {
                                        objectFit: "scale-down",
                                        height: " 100px",
                                      
                                      }),
                                  }}
                                  loading="lazy"
                                />
                                <ImageListItemBar
                                  title={
                                    <Grid
                                      container
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Grid
                                        item
                                        sx={{
                                          fontFamily: "Roboto",
                                          fontSize: "12px",
                                          color: "#000",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        {item}
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          onClick={(e) =>
                                            handleTypeSelect(item, index)
                                          }
                                          sx={{
                                            backgroundColor: "#DCDCDC",
                                            color: "#000",
                                            fontSize: "12px",
                                            marginLeft:"-3px",
                                          }}
                                          size="small"
                                        >
                                          Select
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  }
                                  position="below"
                                />
                              </ImageListItem>
                            );
                          }
                        )}
                      </ImageList>
                    </Grid>
                  </TabPanel>
                )}
              </div>
            )}

            {flag.ExpandProperties && flag.TwoD.mode && flag.TwoD.type && (
              <Properties
                systemData={flag.TwoD.division}
                templateData={flag.TwoD.type}
              />

            )}
          </AppBar>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={4000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            message={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleIcon sx={{ color: 'green', marginRight: '8px' }} />
                {`"${projectName}" has been saved`}
              </span>
            }
            sx={{
              mt: 5,
              '& .MuiPaper-root': {
                backgroundColor: '#E8F5E9', // Light green background
                color: '#2E7D32',           // Dark green text
                padding: '8px 16px',
                borderRadius: '8px',
                boxShadow: 'none',          // Remove box shadow
                fontFamily: 'Roboto, sans-serif',
                fontSize: '0.875rem',       // Font size similar to the image
                display: 'flex',
                alignItems: 'center',
              },
            }}
            action={
              <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />

        </Box>
      )}

    </Box>
  );
};

export default Templates;