import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import Image1 from "./assets/home.png";
import Image2 from "./assets/draw.png";
import Menu from "@mui/material/Menu";
import Image3 from "./assets/bookmark.png";
import Image4 from "./assets/reviews.png";
import Image5 from "./assets/notification.png";
import UserLogo from "./assets/sample_image.jpg";
import Logo from "./assets/sg-logo.png";
import { Tooltip, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import DesignerImg from "./assets/designer.png";
import ThreeDImg from "./assets/3dpreview.png";
import OrdersImg from "./assets/order.png";
import FlagContext from "./flag-context";
import Popover from "@mui/material/Popover";
import PartitionIcon from "./assets/Partition_lite.png";
import Windows from "./assets/windows.png";

console.log("public url: ", process.env.PUBLIC_URL);

const drawerWidth = 50;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: drawerWidth,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        height: "58px",
        borderRadius: "8px",
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    backgroundColor: "#102F66",
    padding: "3% 0 0 0",
    ...openedMixin(theme),
    ...closedMixin(theme),
  },
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const sopen = Boolean(anchorEl);
  const navigate = useNavigate();
  const { flag } = useContext(FlagContext);
  const [activeMenu, setActiveMenu] = React.useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [activeBox, setActiveButton] = React.useState("");

  const handleBoxClick = (button) => {
    setActiveButton(button);
    if (button === "Designer") navigate("/designWithoutBg");
    else if (button === "3D Preview") navigate("/3dViewer");
    else if (button === "Order") navigate("/Orders");
  };

  const [panchorEl, setPanchorEl] = useState(null);

  const handleSubListClick = (event) => {
    setPanchorEl(event.currentTarget);
  };

  const handleSubListClose = () => {
    setPanchorEl(null);
  };
  const handleListClick = (menu, path) => {
    setPanchorEl(null);
    navigate(path);
    setActiveMenu(menu);
  };
  const subopen = Boolean(panchorEl);
  const id = subopen ? "simple-popover" : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
  elevation={0}
  style={{ backgroundColor: "white", width: `calc(100% - ${drawerWidth}px)`}} // Adjust to 98% or 99% as needed
  position="fixed"
  open={open}
>


        <Toolbar>
          <React.Fragment>
            <Box
              component="img"
              sx={{ height: 37 }}
              alt="Saint Gobain"
              src={Logo}
            />
            <Box sx={{ flexGrow: 2 }} />
            {flag.isDesign && (
              <Box
                sx={{ flexGrow: 0, marginLeft: "20%", position: "absolute" }}
                display="flex"
                justifyContent="space-around"
                width="50%"
              >
                <Box
                  onClick={() => handleBoxClick("Designer")}
                  sx={{
                    color: activeBox === "Designer" ? "#214386" : "#757575",
                    fontSize: "14px",
                    fontWeight: activeBox === "Designer" ? "600" : "400",
                    fontFamily: "Roboto",
                    marginBottom: activeBox === "Designer" ? "-12px" : "0px",
                    cursor: "pointer",
                    padding: "10px 10px",
                    borderBottom:
                      activeBox === "Designer" ? "2px solid #214386" : "none",
                  }}
                >
                  { activeBox === "Designer" &&
                    <Box
                      component="img"
                      sx={{
                        height: 24,
                        width: 24,
                        flexGrow: 0,
                        verticalAlign: "middle",
                        marginRight: "4px",
                        fill: activeBox === "Designer" ? "#214386" : "#757575",
                      }}
                      alt="Image 6"
                      src={DesignerImg}
                    />
                  }
                  Designer
                </Box>
                <Box
                  onClick={() => handleBoxClick("3D Preview")}
                  sx={{
                    color: activeBox === "3D Preview" ? "#214386" : "#757575",
                    fontSize: "14px",
                    fontWeight: activeBox === "3D Preview" ? "600" : "400",
                    fontFamily: "Roboto",
                    marginBottom: activeBox === "3D Preview" ? "-12px" : "0px",
                    cursor: "pointer",
                    padding: "10px 10px",
                    borderBottom:
                      activeBox === "3D Preview" ? "2px solid #214386" : "none",
                  }}
                >
                  { activeBox === "3D Preview" &&
                    <Box
                      component="img"
                      sx={{
                        height: 24,
                        width: 24,
                        flexGrow: 0,
                        verticalAlign: "middle",
                        marginRight: "4px",
                        fill: activeBox === "3D Preview" ? "#214386" : "#757575",
                      }}
                      alt="Image 6"
                      src={ThreeDImg}
                    />                  
                  }
                  3D Preview
                </Box>
                <Box
                  onClick={() => handleBoxClick("Order")}
                  sx={{
                    color: activeBox === "Order" ? "#214386" : "#757575",
                    fontSize: "14px",
                    fontWeight: activeBox === "Order" ? "600" : "400",
                    marginBottom: activeBox === "Order" ? "-12px" : "0px",
                    fontFamily: "Roboto",
                    cursor: "pointer",
                    padding: "10px 10px",
                    borderBottom:
                      activeBox === "Order" ? "2px solid #214386" : "none",
                  }}
                >
                  { activeBox === "Order" &&
                    <Box
                      component="img"
                      sx={{
                        height: 24,
                        width: 24,
                        flexGrow: 0,
                        verticalAlign: "middle",
                        marginRight: "4px",
                        fill: activeBox === "Order" ? "#214386" : "#757575",
                      }}
                      alt="Image 6"
                      src={OrdersImg}
                    />                  
                  }
                  Order
                </Box>
              </Box>
            )}

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                component="img"
                sx={{ height: 24, width: 24, flexGrow: 0, marginRight: "25px" }}
                alt="Image 5"
                src={Image5}
              />
              <IconButton
                sx={{ p: 0, marginRight: "5px" }}
                onClick={handleClick}
                size="small"
                aria-controls={sopen ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={sopen ? "true" : undefined}
              >
                <Avatar alt="Remy Sharp" src={UserLogo} />
              </IconButton>
              <Typography
                style={{
                  color: "#212121",
                  fontFamily: "Roboto",
                  fontSize: "small",
                }}
              >
                SG8474232
              </Typography>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Avatar /> My account
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                Add another account
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </React.Fragment>
        </Toolbar>
      </AppBar>

      <Drawer className="app-drawer" variant="permanent" open={open}>
        <List>
          <ListItem
            sx={{
              backgroundColor: activeMenu === "Home" ? "#214386" : "#102F66",
            }}
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "0px",
            }}
          >
            <Tooltip title="Home">
              <ListItemButton onClick={() => handleListClick("Home", "/home")}>
                <ListItemIcon style={{ position: "relative", right: "7px" }}>
                  <Box
                    component="img"
                    sx={{ height: 24, width: 24 }}
                    alt="Image 1"
                    src={Image1}
                  />
                </ListItemIcon>
              </ListItemButton>
            </Tooltip>
          </ListItem>
          <ListItem
            sx={{
              backgroundColor: activeMenu === "Draw" ? "#214386" : "#102F66",
            }}
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "0px",
            }}
          >
            <ListItemButton onMouseEnter={handleSubListClick}>
              <ListItemIcon style={{ position: "relative", right: "7px" }}>
                <Box
                  component="img"
                  sx={{ height: 24, width: 24 }}
                  alt="Image 2"
                  src={Image2}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <Popover
            sx={{
              position: "absolute",
              display: "flex",
              left: "1.3%",
              top: "-2%",
              "& .MuiPaper-root": {
                backgroundColor: "#214386",
                color: "#ffff",
                fontSize: "0.5rem",
                fontWeight: "400",
                fontFamily: "Roboto",
              },
            }}
            id="mouse-over-popover"
            open={subopen}
            anchorEl={panchorEl}
            onClose={handleSubListClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            disableRestoreFocus
          >
            <List>
              <ListItem
                style={{
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  cursor: "pointer",
                }}
                onClick={() => handleListClick("Draw", "/draw")}
              >
                <Box
                  component="img"
                  sx={{ height: 16, width: 16 }}
                  alt="Image 2"
                  src={PartitionIcon}
                />
                <Typography
                  style={{
                    fontSize: "0.7rem",
                    lineHeight: "0",
                    paddingLeft: "5px",
                  }}
                >
                  Partition
                </Typography>
              </ListItem>
              <ListItem
                style={{ paddingTop: "4px", paddingBottom: "4px" }}
                onClick={() => handleListClick("Windows", "/windows")}
              >
                <Box
                  component="img"
                  sx={{ height: 16, width: 16 }}
                  alt="Image 2"
                  src={Windows}
                />
                <Typography
                  style={{
                    fontSize: "0.7rem",
                    lineHeight: "0",
                    paddingLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  Windows
                </Typography>
              </ListItem>
            </List>
          </Popover>
          <ListItem
            sx={{
              backgroundColor: activeMenu === "Project" ? "#214386" : "#102F66",
            }}
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "0px",
            }}
          >
        
          </ListItem>
         
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
      </Box>
    </Box>
  );
}