import React, { useContext, useEffect, useState } from 'react';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Glass from "../../../src/assets/Glass.jpg";
import ClipOn from "../../../src/assets/15003751.png";
import UProfile from "../../../src/assets/15003750.png";
import ppc from "../../../src/assets/ppc.jpg";
import Gasket from "../../../src/assets/Gasket.jpg";
import Screws from "../../../src/assets/screws.png";
import Anchor from "../../../src/assets/anchor.png";
import Mullion from "../../../src/assets/Mullion_orders.jpg";
import './Orders.css';
import ExportIcon from '@mui/icons-material/FileDownload';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import roomImage from '../../assets/room.png';
import FlagContext from '../../flag-context';
import { ProjectContext } from '../home/ProjectContext';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "gray",
    color: "#DCDCDC",
    position: "sticky", // Make header sticky
    top: 0, // Stick it at the top
    zIndex: 1,
    fontFamily: "Roboto",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "small",
    fontFamily: "Roboto",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const data = [
  { materialCode: "6200000120", component: "Glass", description: "SG Clear Tempered with CP 10mm", dimensions: "", price: 27000, img: Glass, unit: "SQM" },
  { materialCode: "15003774", component: "Glass_Joint", description: "LM 2-way in-line polycar for 10mm 3mtr", dimensions: "", img: ppc, price: 2000, unit: "PCE" },
  { materialCode: "15003750", description: "LM U-Shape alum prof 21x30mm L 3m blk",component: "U_Profile", img: UProfile, price: 2500, unit: "PCE" },
  { materialCode: "15003751", description: "LM Alu snap profile 21x30mm L 3m blk",component: "ClipOnBase_Profile", img: ClipOn, price: 3000, unit: "PCE" },
  { materialCode: "15004576", description: "LM Alu snap prof 21x30mm L 3m clip blk",component: "ClipOnClip_Profile", img: ClipOn, price: 3500, unit: "PCE" },
  { materialCode: "15004791", description: "Sleek U prof Gasket for 10mm glass, black",component:"Gasket", img: Gasket, price: 1500, unit: "MTR" },
  { materialCode: "15004076", description: "No. 4X40 metal screw", component: "Accessories",img: Screws,price: 100, unit: "PCE" },
  { materialCode: "15003768", description: "No.4x40 plastic anchor",component: "Accessories", img:Anchor,price: 100, unit: "PCE" },
];
const Orders = () => {
  const [selected, setSelected] = useState([]);
  const { flag, updateFlag } = useContext(FlagContext);
  const { projectName, designName, businessName, customerName, location } = useContext(ProjectContext); // Access projectName and designName
  const [orderNumber] = useState('275063'); // Example order number
  const [customerId] = useState('0000982540'); // Example customer ID

  useEffect(() => {
    // Update the flag to indicate the design mode
    updateFlag("isDesign", true);

    // Reset flag when component unmounts
    return () => updateFlag("isDesign", false);
  }, [updateFlag]);
  const handleSelectAllClick = (event, partitionId) => {
    const partitionMaterials = flag.materials[partitionId];
    const materialCodes = partitionMaterials.Glass.map((glass) => glass.dimensions)
      .concat(partitionMaterials.Glass_Joint.map((_, index) => `Glass_Joint_${partitionId}_${index}`));

    if (event.target.checked) {
      setSelected((prev) => [...new Set([...prev, ...materialCodes])]);
    } else {
      setSelected((prev) => prev.filter((code) => !materialCodes.includes(code)));
    }
  };

  const handleClick = (event, code) => {
    setSelected((prev) => {
      if (prev.includes(code)) {
        return prev.filter((selectedCode) => selectedCode !== code);
      } else {
        return [...prev, code];
      }
    });
  };

  const isSelected = (code) => selected.includes(code);

  const getDescription = (component,type) => {
    const item = data.find(
      (d) => d.component === component && (type === '' || d.description.toLowerCase().includes(type.toLowerCase()))
    );
    return item ? item.description : '';
  };
  
  const getMaterialCode = (component, type) => {
    const item = data.find(
      (d) => d.component === component && (type === '' || d.description.toLowerCase().includes(type.toLowerCase()))
    );
    return item ? item.materialCode : '';
  };
  const getImage = (component,type) => {
    const item = data.find(
      (d) => d.component === component && (type === '' || d.description.toLowerCase().includes(type.toLowerCase()))
    );
    return item ? item.img : '';
  };

  const getPartitionName = (partitionId) => {
    const partition = flag.Data2D.find((p) => p.id === partitionId);
    return partition ? `${partition.type} - Sleek` : `Partition ${partitionId}`;
  };

  const getUnits = (component,type) => {
    const item = data.find(
      (d) => d.component === component && (type === '' || d.description.toLowerCase().includes(type.toLowerCase()))
    );
    return item ? item.unit : '';
  };
  const getUnitPrice = (component,type) => {
    const item = data.find(
      (d) => d.component === component && (type === '' || d.description.toLowerCase().includes(type.toLowerCase()))
    );
    return item ? item.price : '';
  };

  return (
    <div class="wrapper">
    <div className="order-container">
      <div className="order-header">
        <img src={roomImage} alt="Project" className="project-image" />
        <div className="order-details">
          <h2 className="project-name">
            {projectName || "Not available"} <span className="floor-label">{designName || "Not available"}</span>
          </h2>
            <div className="order-info">
              <div>
                <span>Order number</span>
                <p>{orderNumber}</p>
              </div>
              <div>
                <span>Business</span>
                <p>{businessName || "Not available"}</p>
              </div>
              <div>
                <span>Customer ID</span>
                <p>{customerId}</p>
              </div>
              <div>
                <span>Customer name</span>
                <p>{customerName || "Not available"}</p>
              </div>
              <div>
                <span>Location</span>
                <p>{location || "Not available"}</p>
              </div>
              <div>
                <span>Designer name</span>
                <p></p>
              </div>
            </div>
          </div>
          <div className="export-buttons">
            <button className="export-button">
              <ExportIcon style={{ marginRight: 4 }} /> Export as Excel
            </button>
            <button className="export-button pdf">
              <PdfIcon style={{ marginRight: 4 }} /> Export as PDF
            </button>
          </div>
        </div>
      </div>
  
      {/* Tables Section */}
      <div class="bom-table">
        {Object.entries(flag.materials).map(([partitionId, partitionData], partitionIndex) => (
  <TableContainer
  component={Paper}
  sx={{
    maxHeight: '500px', // Set max height for scrolling
    width: '100%',
    overflowY: 'auto', // Enable vertical scrolling
    '::-webkit-scrollbar': {
      width: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#5E8DE9',
      borderRadius: '5px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#5E8DE9',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '5px',
    },
  }}
>         <caption style={{ captionSide: "top", textAlign: "left", fontSize: "14px", fontWeight: 500, margin: '10px', display: "inline-block" }}>
           {getPartitionName(partitionId)}
         </caption>
         <Table size="small" sx={{ minWidth: 1350 }} aria-label={`table for partition ${partitionId}`} stickyHeader>
           <TableHead>
             <TableRow>
               <StyledTableCell padding="checkbox">
                 <Checkbox
                   color="primary"
                   indeterminate={
                     selected.filter((code) =>
                       partitionData.Glass.map((g) => g.dimensions)
                         .concat(partitionData.Glass_Joint.map((_, i) => `Glass_Joint_${partitionId}_${i}`))
                         .includes(code)
                     ).length > 0 &&
                     selected.length < partitionData.Glass.length + partitionData.Glass_Joint.length
                   }
                   checked={
                     selected.filter((code) =>
                       partitionData.Glass.map((g) => g.dimensions)
                         .concat(partitionData.Glass_Joint.map((_, i) => `Glass_Joint_${partitionId}_${i}`))
                         .includes(code)
                     ).length === partitionData.Glass.length + partitionData.Glass_Joint.length
                   }
                   onChange={(e) => handleSelectAllClick(e, partitionId)}
                 />
               </StyledTableCell>
               <StyledTableCell>SI. No</StyledTableCell>
               <StyledTableCell>Material Code</StyledTableCell>
               <StyledTableCell>Description</StyledTableCell>
               <StyledTableCell>Reference Image</StyledTableCell>
               <StyledTableCell>Dimensions (mm)</StyledTableCell>
               <StyledTableCell>Qty</StyledTableCell>
               <StyledTableCell>Unit</StyledTableCell>
               <StyledTableCell>Rate</StyledTableCell>
               <StyledTableCell>Total</StyledTableCell>
             </TableRow>
           </TableHead>
           <TableBody>
          
             {partitionData.Glass.map((glass, index) => (
               <StyledTableRow
                 key={`${glass.dimensions}_${partitionId}`}
                 selected={isSelected(glass.dimensions)}
                 onClick={(event) => handleClick(event, glass.dimensions)}
               >
                 <StyledTableCell padding="checkbox">
                   <Checkbox
                     color="primary"
                     checked={isSelected(glass.dimensions)}
                     inputProps={{ "aria-labelledby": `glass-${partitionId}-${index}` }}
                   />
                 </StyledTableCell>
                 <StyledTableCell>1</StyledTableCell>
                 <StyledTableCell>{getMaterialCode('Glass',"")}</StyledTableCell>
                 <StyledTableCell>{getDescription("Glass","")}</StyledTableCell>
                 <StyledTableCell> <img src={getImage("Glass","")} alt={getDescription("Glass","")} width="60" height="60" style={{ objectFit: "contain" }} />
                 </StyledTableCell>

                 <StyledTableCell>{glass.dimensions}</StyledTableCell>
                 <StyledTableCell>{glass.quantity}</StyledTableCell>
                 <StyledTableCell>{getUnits('Glass',"")}</StyledTableCell>
                 <StyledTableCell>{getUnitPrice('Glass',"")}</StyledTableCell>
                 <StyledTableCell>{glass.quantity *getUnitPrice('Glass',"") }</StyledTableCell>
               </StyledTableRow>
               
             ))}
             {partitionData.Glass_Joint.map((joint, index) => (
               <StyledTableRow
                 key={`Glass_Joint_${partitionId}_${index}`}
                 selected={isSelected(`Glass_Joint_${partitionId}_${index}`)}
                 onClick={(event) => handleClick(event, `Glass_Joint_${partitionId}_${index}`)}
               >
                 <StyledTableCell padding="checkbox">
                   <Checkbox
                     color="primary"
                     checked={isSelected(`Glass_Joint_${partitionId}_${index}`)}
                     inputProps={{ "aria-labelledby": `joint-${partitionId}-${index}` }}
                   />
                 </StyledTableCell>
                 <StyledTableCell>2</StyledTableCell>
                 <StyledTableCell>{getMaterialCode('Glass_Joint',"")}</StyledTableCell>
                 <StyledTableCell>{getDescription("Glass_Joint","")}</StyledTableCell>
                 <StyledTableCell> <img src={getImage("Glass_Joint","")} alt={getDescription("Glass_Joint","")} width="60" height="60" style={{ objectFit: "contain" }} />
                 </StyledTableCell>

                 <StyledTableCell></StyledTableCell>
                 <StyledTableCell>{joint.quantity}</StyledTableCell>
                 <StyledTableCell>{getUnits('Glass_Joint',"")}</StyledTableCell>
                 <StyledTableCell>{getUnitPrice('Glass_Joint',"")}</StyledTableCell>
                 <StyledTableCell>{joint.quantity *getUnitPrice('Glass_Joint',"") }</StyledTableCell>
               </StyledTableRow>
             ))}
             {/* Add U-Profile and Clip-on Profile */}
             {partitionData.U_Profile.map((profile, index) => (
               <StyledTableRow
                 key={`U_Profile_${partitionId}_${index}`}
                 selected={isSelected(`U_Profile_${partitionId}_${index}`)}
                 onClick={(event) => handleClick(event, `U_Profile_${partitionId}_${index}`)}
               >
                 <StyledTableCell padding="checkbox">
                   <Checkbox
                     color="primary"
                     checked={isSelected(`U_Profile_${partitionId}_${index}`)}
                     inputProps={{ "aria-labelledby": `uprofile-${partitionId}-${index}` }}
                   />
                 </StyledTableCell>
                 <StyledTableCell>3</StyledTableCell>
                 <StyledTableCell>{getMaterialCode('U_Profile',"")}</StyledTableCell>
                 <StyledTableCell>{getDescription("U_Profile","")}</StyledTableCell>
                 <StyledTableCell> <img src={getImage("U_Profile","")} alt={getDescription("U_Profile","")} width="60" height="60" style={{ objectFit: "contain" }} />
                 </StyledTableCell>
                 
                 <StyledTableCell></StyledTableCell>
                 <StyledTableCell>{profile.quantity}</StyledTableCell>
                 <StyledTableCell>{getUnits('U_Profile',"")}</StyledTableCell>
                 <StyledTableCell>{getUnitPrice('U_Profile',"")}</StyledTableCell>
                 <StyledTableCell>{profile.quantity *getUnitPrice('U_Profile',"") }</StyledTableCell>
               </StyledTableRow>
             ))}
              {partitionData.ClipOnBase_Profile.map((profile, index) => (
               <StyledTableRow
                 key={`ClipOnBaseProfile_${partitionId}_${index}`}
                 selected={isSelected(`ClipOnBase_Profile_${partitionId}_${index}`)}
                 onClick={(event) => handleClick(event, `ClipOnBase_Profile_${partitionId}_${index}`)}
               >
                 <StyledTableCell padding="checkbox">
                   <Checkbox
                     color="primary"
                     checked={isSelected(`ClipOnBase_Profile_${partitionId}_${index}`)}
                     inputProps={{ "aria-labelledby": `cliponbaseprofile-${partitionId}-${index}` }}
                   />
                 </StyledTableCell>
                 <StyledTableCell>4</StyledTableCell>
                 <StyledTableCell>{getMaterialCode('ClipOnBase_Profile',"")}</StyledTableCell>
                 <StyledTableCell>{getDescription("ClipOnBase_Profile","")}</StyledTableCell>
                 <StyledTableCell> <img src={getImage("ClipOnBase_Profile","")} alt={getDescription("ClipOn_Profile","")} width="60" height="60" style={{ objectFit: "contain" }} />
                 </StyledTableCell>
                 <StyledTableCell></StyledTableCell>
                 <StyledTableCell>{profile.quantity}</StyledTableCell>
                 <StyledTableCell>{getUnits('ClipOnBase_Profile',"")}</StyledTableCell>
                 <StyledTableCell>{getUnitPrice('ClipOnBase_Profile',"")}</StyledTableCell>
                 <StyledTableCell>{profile.quantity *getUnitPrice('ClipOnBase_Profile',"") }</StyledTableCell>
               </StyledTableRow>
             ))}
              {partitionData.ClipOnClip_Profile.map((profile, index) => (
               <StyledTableRow
                 key={`ClipOnClip_Profile_${partitionId}_${index}`}
                 selected={isSelected(`ClipOnClip_Profile_${partitionId}_${index}`)}
                 onClick={(event) => handleClick(event, `ClipOnClip_Profile_${partitionId}_${index}`)}
               >
                 <StyledTableCell padding="checkbox">
                   <Checkbox
                     color="primary"
                     checked={isSelected(`ClipOnClip_Profile_${partitionId}_${index}`)}
                     inputProps={{ "aria-labelledby": `cliponclipprofile-${partitionId}-${index}` }}
                   />
                 </StyledTableCell>
                 <StyledTableCell>5</StyledTableCell>
                 <StyledTableCell>{getMaterialCode('ClipOnClip_Profile',"")}</StyledTableCell>
                 <StyledTableCell>{getDescription("ClipOnClip_Profile","")}</StyledTableCell>
                 <StyledTableCell> <img src={getImage("ClipOnClip_Profile","")} alt={getDescription("ClipOn_Profile","")} width="60" height="60" style={{ objectFit: "contain" }} />
                 </StyledTableCell>
                 <StyledTableCell></StyledTableCell>
                 <StyledTableCell>{profile.quantity}</StyledTableCell>
                 <StyledTableCell>{getUnits('ClipOnClip_Profile',"")}</StyledTableCell>
                 <StyledTableCell>{getUnitPrice('ClipOnClip_Profile',"")}</StyledTableCell>
                 <StyledTableCell>{profile.quantity *getUnitPrice('ClipOnClip_Profile',"") }</StyledTableCell>
               </StyledTableRow>
             ))}
               {partitionData.Gasket.map((gasket, index) => (
               <StyledTableRow
                 key={`Gasket${partitionId}_${index}`}
                 selected={isSelected(`Gasket_${partitionId}_${index}`)}
                 onClick={(event) => handleClick(event, `Gasket_${partitionId}_${index}`)}
               >
                 <StyledTableCell padding="checkbox">
                   <Checkbox
                     color="primary"
                     checked={isSelected(`Gasket_${partitionId}_${index}`)}
                     inputProps={{ "aria-labelledby": `gasket-${partitionId}-${index}` }}
                   />
                 </StyledTableCell>
                 <StyledTableCell>6</StyledTableCell>
                 <StyledTableCell>{getMaterialCode('Gasket',"")}</StyledTableCell>
                 <StyledTableCell>{getDescription("Gasket","")}</StyledTableCell>
                 <StyledTableCell> <img src={getImage("Gasket","")} alt={getDescription("Gasket","")} width="60" height="60" style={{ objectFit: "contain" }} />
                 </StyledTableCell>
                 <StyledTableCell></StyledTableCell>
                 <StyledTableCell>{gasket.quantity}</StyledTableCell>
                 <StyledTableCell>{getUnits('Gasket',"")}</StyledTableCell>
                 <StyledTableCell>{getUnitPrice('Gasket',"")}</StyledTableCell>
                 <StyledTableCell>{gasket.quantity *getUnitPrice('Gasket',"") }</StyledTableCell>
               </StyledTableRow>
             ))}
              {partitionData.Accessories.map((accessory, index) => (
               <>
               <StyledTableRow
                 key={`Accessories${partitionId}_${index}`}
                 selected={isSelected(`Accessories_${partitionId}_${index}`)}
                 onClick={(event) => handleClick(event, `Accessories_${partitionId}_${index}`)}
               >
                 <StyledTableCell padding="checkbox">
                   <Checkbox
                     color="primary"
                     checked={isSelected(`Accessories_${partitionId}_${index}`)}
                     inputProps={{ "aria-labelledby": `accessories-${partitionId}-${index}` }}
                   />
                 </StyledTableCell>
                 <StyledTableCell>7</StyledTableCell>
                 <StyledTableCell>{getMaterialCode('Accessories',"screw")}</StyledTableCell>
                 <StyledTableCell>{getDescription("Accessories","screw")}</StyledTableCell>
                 <StyledTableCell> <img src={getImage("Accessories","screw")} alt={getDescription("Accessories","screw")} width="60" height="60" style={{ objectFit: "contain" }} />
                 </StyledTableCell>
                 <StyledTableCell></StyledTableCell>
             
                 <StyledTableCell>{accessory.screwsQty}</StyledTableCell>
                 <StyledTableCell>{getUnits('Accessories',"screw")}</StyledTableCell>
                 <StyledTableCell>{getUnitPrice('Accessories',"screw")}</StyledTableCell>
                 <StyledTableCell>{accessory.quantity *getUnitPrice('Accessories',"screw") }</StyledTableCell>
               </StyledTableRow>
                </>
             ))}
               {partitionData.Accessories.map((accessory, index) => (
               <>
               <StyledTableRow
                 key={`Accessories${partitionId}_${index}`}
                 selected={isSelected(`Accessories_${partitionId}_${index}`)}
                 onClick={(event) => handleClick(event, `Accessories_${partitionId}_${index}`)}
               >
                 <StyledTableCell padding="checkbox">
                   <Checkbox
                     color="primary"
                     checked={isSelected(`Accessories_${partitionId}_${index}`)}
                     inputProps={{ "aria-labelledby": `accessories-${partitionId}-${index}` }}
                   />
                 </StyledTableCell>
                 <StyledTableCell>8</StyledTableCell>
                 <StyledTableCell>{getMaterialCode('Accessories',"anchor")}</StyledTableCell>
                 <StyledTableCell>{getDescription("Accessories","anchor")}</StyledTableCell>
                 <StyledTableCell> <img src={getImage("Accessories","anchor")} alt={getDescription("Accessories","anchor")} width="60" height="60" style={{ objectFit: "contain" }} />
                 </StyledTableCell>
                 <StyledTableCell></StyledTableCell>
                
                 <StyledTableCell>{accessory.plasticAnchorQty}</StyledTableCell>
                 <StyledTableCell>{getUnits('Accessories',"anchor")}</StyledTableCell>
                 <StyledTableCell>{getUnitPrice('Accessories',"anchor")}</StyledTableCell>
                 <StyledTableCell>{accessory.quantity *getUnitPrice('Accessories',"anchor") }</StyledTableCell>
         
               </StyledTableRow>
                </>
             ))}
           </TableBody>
         </Table>
       </TableContainer>
     ))}
   </div>
   </div>
  );
};

export default Orders;