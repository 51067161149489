import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Close as CloseIcon, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Radio,
  Grid,
  InputAdornment,
  Slider,
  Link,
  Button,
  Snackbar,
  IconButton,
} from "@mui/material";
import Save from '../../assets/save.png'; // Icon import for save preview

function CustomDialog({ type, onClose, partition1, partition2 }) {
  const [NewValue, setNewValue] = useState("");
  const [VAL, setVAL] = useState("");
  const [ALL, setALL] = useState(false);

  const handleApply = () => {
    onClose(partition1, partition2);
  };

  const setNewValue_ = (val) => {
    console.log(val)
    if(type === "90"){
      partition1.intersectionJoint[partition2.id] = val
      partition2.intersectionJoint[partition1.id] = val
    }
    if(type === "GlassJoint" || type === "GlassWidth"){
      partition1[labels[type]] = val
    }
    setNewValue(val)
  } 

  useEffect( () => {

    if(type === "90"){
      console.log(partition1, partition2)
      
      if(!partition1.intersectionJoint[partition2.id]){
        partition1.intersectionJoint[partition2.id] = opt1[type][0]
      }
      
      if(!partition2.intersectionJoint[partition1.id]){
        partition2.intersectionJoint[partition1.id] = opt1[type][0]
      }
  
      setVAL(partition1.intersectionJoint[partition2.id] || opt1[type][0])
    }

    if(type === "GlassJoint" || type === "GlassWidth"){
      console.log(partition1)

      setVAL(partition1[labels[type]])
    }


  },[])

  const labels = {
    "90" : "Glass Joint",
    "GlassJoint" : "Glass Joint",
    "GlassWidth" : "Glass Width",
  }
  const opt1 = {
    "90" : ["90 degree Polycarbonate", "90 degree Aluminium Profile"],
    "GlassJoint" : ["Two-way Polycarbonate", "Mullion"]
  }  

  const min = 700
  const max = 1500

  return (
    <Dialog open={type ? true : false} onClose={() => onClose()} fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 2 }}>
        <Typography variant="h6" style={{ fontWeight: '600',fontFamily: 'Poppins',fontSize:'16px' }}>
          Custom Changes
        </Typography>
        <IconButton onClick={() => onClose()} edge="end">
          <CloseIcon style={{color:'#000'}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" mb={2}>
        <img src={Save} alt="Save" style={{ width: 56, height: 56 }} />

        </Box>
        <Typography variant="body1" gutterBottom style={{   fontFamily: "Roboto",fontWeight:600,fontSize:'15px',textAlign:'center'}}>
          Do you want to change the {labels[type]}?
        </Typography>

        <Box mt={3}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: 8 }}>
            Selected {labels[type]}:
          </Typography>

          <FormControl fullWidth style={{ marginBottom: 16 }}>

            {type === "GlassWidth" &&
            <TextField            
              disabled
              label={labels[type]}
              value={VAL}             
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span style={{ fontSize: "12px" }}>mm</span>
                  </InputAdornment>
                ),
                inputProps: {
                  "aria-label": "panel height",
                  min: min,
                  max: max,
                },
              }}
              variant="outlined"
            />
            }

            {type !== "GlassWidth" &&   
            <TextField
              select              
              label={labels[type]}
              value={VAL}
              fullWidth            
            >         
            {opt1[type] && opt1[type].map( each => {
                if(each === VAL){
                  return (
                    <MenuItem value={each}>{each}</MenuItem>
                  )
                }
              })
            }            
            </TextField>
            }
          </FormControl>

          <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: 8 }}>
            Change {labels[type]}:
          </Typography>

          <FormControl fullWidth style={{ marginBottom: 16 }}>

            {type === "GlassWidth" &&
            <TextField            
            label={labels[type]}
            value={NewValue}
            onChange={(e) => setNewValue_(e.target.value)}
            onBlur={() => {
              const value = parseInt(NewValue, 10);
              if (value < min || value > max) {
                setNewValue_(max.toString());
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span style={{ fontSize: "12px" }}>mm</span>
                </InputAdornment>
              ),
              inputProps: {
                "aria-label": "panel height",
                min: min,
                max: max,
              },
            }}
            variant="outlined"
            helperText={
              <Typography
                variant="caption"
                display="flex"
                gutterBottom
                color="textSecondary"
                sx={{
                  fontSize: "0.5rem",
                  margin: "0px",
                }}
              >
                <IconButton size="small" color="info">
                  <InfoOutlined sx={{ fontSize: "1rem" }} fontSize="small" />
                </IconButton>
                <span
                  style={{
                    fontSize: ".7rem",
                    margin: "0px",
                    paddingTop: "5px",
                    textAlign: "left",
                  }}
                >
                  Min value is {min} mm and Max value is {max} mm{" "}
                </span>
              </Typography>
            }
          />
            }

            {type !== "GlassWidth" &&
            <TextField
              select              
              label={labels[type]}
              value={NewValue}
              onChange={(e) => setNewValue_(e.target.value)}
              fullWidth            
            >    
              {/* <MenuItem value="">Select</MenuItem> */}
              {opt1[type] && opt1[type].map( each => {
                if(each !== VAL){
                  return (
                    <MenuItem value={each}>{each}</MenuItem>
                  )
                }
              })}
            </TextField>
            }
          </FormControl>

          { (type === "GlassWidth" || type === "GlassJoint") &&

          <>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: 8 }}>
            Do you want to apply this change to Selected Panel or Entire Layout ?
          </Typography>

          <RadioGroup
              value={ALL}
              onChange={(e) => setALL(e.target.value)}
              row
          >
              <FormControlLabel
                key={"false"}
                value={false}
                control={<Radio />}
                label={"Selected Panel"}
              />
              {" "}
              <FormControlLabel
                key={"true"}
                value={true}
                control={<Radio />}
                label={"Entire Layout"}
              />
            </RadioGroup>
          </>
          }
          

        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', paddingBottom: 16 }}>
        <Button
          onClick={() => onClose()}
          variant="outlined"
          style={{
            color: '#000',
            borderColor: '#000',
            fontWeight: 'bold',
            marginRight: 8,
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleApply}
          style={{
            backgroundColor: '#214386',
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
       Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
