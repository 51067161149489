import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField, Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Box, Card, CardContent, Typography, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Collapse
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './home.css';
import Refresh from '../../assets/refresh.png';
import FlagContext from '../../flag-context';
import { UserContext } from '../../context/UserContext';
import { CustomerContext } from '../../context/CustomerContext';
import {BusinessContext} from '../../context/BusinessContext';
 import { ProjectContext } from './ProjectContext';
 import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';

const HomePage = () => {
  const { projects, addProject, deleteDesign, designs, addDesign, fetchDesignByProjectId, fetchProjects, setBusinessName,setCustomerName,setLocation } = useContext(ProjectContext);
  const { customers } = useContext(CustomerContext);
  const { business } = useContext(BusinessContext);
  const { setProjectName, setDesignName } = useContext(ProjectContext);

  const BASE_URL = process.env.REACT_APP_BASE_API_URL;

  const [formData, setFormData] = useState({
    project_name: '',
    project_location: '',
    business_name: '',
    start_date: '',
    end_date: '',
    customer_name: '',
    project_status: 'Started',
    design_count: 0,
    customer_name: '',
    user_id: 1,
   
  });
  const [searchTerm, setSearchTerm] = useState('');
 
  const [open, setOpen] = useState(false);
  const { updateFlag } = useContext(FlagContext);
  const [openDesignDialog, setOpenDesignDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [newDesignName, setNewDesignName] = useState(''); // Renamed to avoid conflict
  const [projectId, setProjectId] = useState('');
  const [errors, setErrors] = useState({});
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [openRows, setOpenRows] = useState({});
  const navigate = useNavigate(); 

  const handleRefresh = () => {
    fetchProjects(); // Fetches the latest project data
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredProjects = projects.filter((project) =>
    (project.project_name || '')?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
    (project.project_location || '')?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
    (project.business_name || '')?.toLowerCase().includes(searchTerm?.toLowerCase())
  );
  
  const validate = () => {
    let tempErrors = {};
    if (new Date(formData.end_date) < new Date(formData.start_date)) {
      tempErrors.end_date = "End date cannot be before start date";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const newProject = {
        ...formData,
        designs: [], 
      };
      addProject(newProject); 

      setFormData({
        project_name: '',
        project_location: '',
        business_name: '',
        start_date: '',
        end_date: '',
        customer_name: '',
        project_status: 'Started',
        design_count : 0,
        customer_name : '',
        user_id: '1',
      });
      setErrors({});
      setOpen(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddDesignClick = (project) => {
    setSelectedProject(project);
    setOpenDesignDialog(true);
  };

  const handleDesignDialogClose = () => {
    setOpenDesignDialog(false);
    setNewDesignName(''); // Use newDesignName here
    setProjectId('');
  };
  const handleDesignSubmit = () => {
    if (newDesignName) {  // Use newDesignName here
      const designData = { "design_name": newDesignName, "project_id": projectId };
      addDesign(designData);
      handleDesignDialogClose();
    }
  };

  const handleEditDesign = async (project, design) => {
    try {
      const designId = design.design_id; // Make sure this is the correct designId for the design being edited
      updateFlag("TwoD", { designID: designId})

      
      const response = await fetch(`${BASE_URL}/designs/${designId}`, {

        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch design data");
      }
  
      const data = await response.json();
      const savedData = data.data.design_attributes?.Data2D || [];

  
      updateFlag("Data2D", savedData); // Load saved 2D data for the specific design
  
      // Update other project details in context
      setBusinessName && setBusinessName(project.business_name);
      setCustomerName && setCustomerName(project.customer_name);
      setLocation && setLocation(project.project_location);

      setProjectName(project.project_name);
      updateFlag("TwoD", { projectName: project.project_name})
      setDesignName(design.design_name);


      // Navigate to the drawing page
      navigate("/designWithoutBg", {
        state: {
          projectId: project.project_id,
          projectName: project.project_name,
          designId: designId, // Correct designId should be passed here
          designName: design.design_name,
          designID: design.design_id,
          businessName: project.business_name,
          customerName: project.customer_name,
          location: project.project_location,
        },
      });
    } catch (error) {
      console.error("Error fetching design data:", error);
    }
  };
  

  
  
  
  const handleDeleteDesign = async (projectId, designId) => {
    console.log("Deleting design with ID:", designId); // Debugging log
    try {
      const response = await fetch(`${BASE_URL}/designs/${designId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete design');
      }
  
      // Fetch updated designs for the project after deletion
      fetchDesignByProjectId(projectId);
    } catch (error) {
      console.error('Error deleting design:', error);
    }
  };

  const toggleRow = (index) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [index]: !prevOpenRows[index],
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); 
  };

  return (

    <React.Fragment>
    <Box id="home-page" display="flex" flexDirection="column" alignItems="center" height="80vh">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card elevation={0} sx={{ minWidth: "100%", marginRight: '25px' }}>
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: '600', color: '#333' }} component="div">
                  Active Projects
                </Typography>
                <Typography style={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '400', color: '#777' }}>
                  View all new and existing design files in the table below.
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '50%' }}>
                <TextField
                  style={{ fontFamily: 'Roboto', fontSize: '12px', width: '40%', borderRadius: '8px' }}
                  label="Search"
                  name="search"
                  placeholder="Search by project, location...etc"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  fullWidth
                  autoFocus
                  InputProps={{
                    style: { fontFamily: 'Roboto', fontSize: '12px', borderRadius: '8px' },
                  }}
                  InputLabelProps={{
                    style: { fontFamily: 'Roboto', fontSize: '12px', color: '#999' },
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      color: "#191919",
                      fontWeight: 400,
                    },
                  }}
                />
                <Button
                  onClick={handleClickOpen}
                  startIcon={<ControlPointRoundedIcon />}
                  variant="contained"
                  style={{ paddingBottom: 11, paddingTop: 11, marginLeft: 15, background: '#5E8DE9'
                  }}
                >
                  Add New Project
                </Button>
                <Box className="refresh-icon">
                  <IconButton onClick={handleRefresh}>
                    <img src={Refresh} alt="Refresh" />
                  </IconButton>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
        <TableContainer
  component={Paper}
  sx={{
    maxHeight: '500px', // Set max height for scrolling
    width: '100%',
    overflowY: 'auto', // Enable vertical scrolling
    '::-webkit-scrollbar': {
      width: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#5E8DE9',
      borderRadius: '5px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#5E8DE9',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '5px',
    },
  }}
>
<Table stickyHeader>
    <TableHead>
      <TableRow className="table-header">
        <TableCell className="project-name-header table-cell" style={{ width: '20%' }}>
          Project Name
        </TableCell>
        <TableCell className="table-cell" style={{ width: '10%' }}>
          Business
        </TableCell>
        <TableCell className="table-cell" style={{ width: '10%' }}>
          Customer Name
        </TableCell>
        <TableCell className="table-cell" style={{ width: '10%' }}>
          Location
        </TableCell>
        <TableCell className="table-cell" style={{ width: '13%' }}>
          Duration
        </TableCell>
        <TableCell className="table-cell" style={{ width: '10%' }}>
          Status
        </TableCell>
        <TableCell className="table-cell" style={{ width: '10%' }}>
          Add Design
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {filteredProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project, index) => (
        <React.Fragment key={index}>
          <TableRow className="table-body-row">



          <TableCell className="project-name-cell table-cell">
  <IconButton
    size="small"
    onClick={() => {
      fetchDesignByProjectId(project.project_id);
      toggleRow(index);
    }}
    disabled={project.design_count === 0}
    className="dropdown-icon"
  >
    {openRows[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
  </IconButton>
  {project.project_name}
  {project.design_count > 0 && (
    <span className="design-count-box">{project.design_count}</span>
  )}
</TableCell>





            <TableCell className="table-cell">{project.business_name}</TableCell>
            <TableCell className="table-cell">{project.customer_name}</TableCell>
            <TableCell className="table-cell">{project.project_location}</TableCell>
            <TableCell className="table-cell">{`${project.start_date} — ${project.end_date}`}</TableCell>
            <TableCell className="table-cell">
              <span className="status-chip">{project.project_status}</span>
            </TableCell>
            <TableCell className="table-cell">
              <Button onClick={() => handleAddDesignClick(project)} className="add-design-button">
                Add new
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size="small" aria-label="design-details" className="design-details-table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="design-details-table design-name-header">Design Name</TableCell>
                        <TableCell className="design-details-table last-update-header">Last Update</TableCell>
                        <TableCell className="design-details-table actions-header">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {designs.map((design, i) => (
                        <TableRow key={i}>
                          <TableCell className="design-details-table design-name-cell">{design.design_name}</TableCell>
                          <TableCell className="design-details-table last-update-cell">{design.last_updated_date}</TableCell>
                          <TableCell className="design-details-table actions-cell">
                          
                          
                            <IconButton aria-label="notes">
                              <CommentIcon className="icon notes" />
                            </IconButton>
                            <IconButton aria-label="edit" onClick={() => handleEditDesign(project, design)}>
                              <EditIcon className="icon edit" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                console.log('Project ID:', project.project_id, 'Design ID:', design.design_id);
                                handleDeleteDesign(project.project_id, design.design_id);
                              }}
                            >
                              <DeleteIcon className="icon delete" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </TableBody>
  </Table>
</TableContainer>


  <TablePagination
      component="div"
      count={filteredProjects.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[6, 10, 15]} // Add 6 as an option
    />
</Grid>

        </Grid>

        {/* Add New Project Dialog */}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">
  <Typography sx={{ fontWeight: 'bold' }}>
    Add new project
  </Typography>
  <IconButton
    aria-label="close"
    onClick={handleClose}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <CloseIcon />
  </IconButton>
</DialogTitle>

          <DialogContent sx={{ paddingTop: '20px' }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Project Name"
                    name="project_name"
                    value={formData.project_name}
                    onChange={handleChange}
                    fullWidth
                    required
                    className="dialog-input"
                    InputLabelProps={{
                      style: { marginTop:'5.1px', fontFamily: 'Poppins', fontWeight: 500, fontSize: '14px', color: '#333' },
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField
                  
                    label="Customer Name"
                    name="customer_name"
                    value={formData.customer_name}
                    onChange={handleChange}
                    fullWidth
                    required
                    className="dialog-input"
                    InputLabelProps={{
                      style: { fontFamily: 'Poppins', fontWeight: 500, fontSize: '14px', color: '#333' },
                      shrink: true,
                    }}
                    >
                   
                  </TextField>
                </Grid>
               
                <Grid item xs={6}>
                <TextField
                    select
                    label="Business"
                    name="business_id"
                    value={formData.business_id}
                    onChange={handleChange}
                    fullWidth
                    required
                    className="dialog-input"
                    InputLabelProps={{
                      style: { fontFamily: 'Poppins', fontWeight: 500, fontSize: '14px', color: '#333' },
                      shrink: true,
                    }}
                  >
                    <MenuItem value=""><em>Select Business</em></MenuItem>
                    {
                      business && business.map((singlebusiness)=>{
                          return(
                          <MenuItem value={singlebusiness.business_id} key={singlebusiness.business_id}>{singlebusiness.business_name}</MenuItem>)
                      })
                    }
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Project Location"
                    name="project_location"
                    value={formData.project_location}
                    onChange={handleChange}
                    fullWidth
                    required
                    className="dialog-input"
                    InputLabelProps={{
                      style: { fontFamily: 'Poppins', fontWeight: 500, fontSize: '14px', color: '#333' },
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    label="Start Date"
                    name="start_date"
                    value={formData.start_date}
                    onChange={handleChange}
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true, style: { fontFamily: 'Poppins', fontWeight: 500, fontSize: '14px', color: '#333' } }}
                    className="dialog-input"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    label="End Date"
                    name="end_date"
                    value={formData.end_date}
                    onChange={handleChange}
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true, style: { fontFamily: 'Poppins', fontWeight: 500, fontSize: '14px', color: '#333' } }}
                    className="dialog-input"
                    error={!!errors.end_date}
                    helperText={errors.end_date}
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={handleClose} variant="contained" className="cancel-button">Cancel</Button>
                <Button type="submit" variant="contained" color="primary" className="create-button">ADD Project</Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>

        {/* Add Design Dialog */}
        <Dialog open={openDesignDialog} onClose={handleDesignDialogClose} aria-labelledby="design-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="design-dialog-title">
  <Typography sx={{ fontWeight: 'bold' }}>
    Add new design
  </Typography>
  <IconButton
    aria-label="close"
    onClick={handleDesignDialogClose}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <CloseIcon />
  </IconButton>
</DialogTitle>

          <DialogContent>
            {selectedProject && (
              <Box sx={{ marginBottom: 2, padding: 2, backgroundColor: '#f6f6f6', borderRadius: '8px', border: '1px solid #e0e0e0' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Project Name
                    </Typography>
                    <Typography variant="body1" fontWeight="600">
                      {selectedProject.project_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Business
                    </Typography>
                    <Typography variant="body1" fontWeight="600">
                      {selectedProject.business_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Customer Name
                    </Typography>
                    <Typography variant="body1" fontWeight="600">
                      {selectedProject.customer_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Project Location
                    </Typography>
                    <Typography variant="body1" fontWeight="600">
                      {selectedProject.project_location}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Start and End Date
                    </Typography>
                    <Typography variant="body1" fontWeight="600">
                      {`${selectedProject.start_date} — ${selectedProject.end_date}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
         <TextField
  label="Design name"
  name="design_name"
  value={newDesignName} // Use newDesignName here
  onChange={(e) => {
    setNewDesignName(e.target.value); // Use setNewDesignName here
    setProjectId(selectedProject?.project_id);
  }}
  fullWidth
  required
  InputLabelProps={{
    shrink: true,
  }}
  sx={{ marginBottom: -1 }}
/>
          </DialogContent>
          <DialogActions>
  <Button
    onClick={handleDesignDialogClose}
    variant="outlined"
    sx={{
      backgroundColor: "#fff", // Red background for Cancel
      color: "black", // White text
    }}
  >
    Cancel
  </Button>
  <Button
    onClick={handleDesignSubmit}
    variant="contained"
    sx={{
      backgroundColor: "#214386", // Blue background for Add Design
      color: "#fff", // White text
    }}
  >
    Add Design
  </Button>
</DialogActions>

        </Dialog>
      </Box>
    </React.Fragment>
  );
};

export default HomePage;