import React, { useState, useEffect, useRef, useContext } from 'react';
import { Stage, Layer, Line, Shape, Text, Arc ,Circle} from 'react-konva';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import './drawing-app.css';
import FlagContext from '../../flag-context';
import CustomDialog from '../reusable-ui-components/Dialog'
import Orders from '../Templates'
import { getRuleByName, getConstants } from '../../service/rules.service';


const gridSize = 10; // Set the grid size
const maxSegmentLength = 1500; // Maximum segment length in units (e.g., millimeters)
const sectorAngle = 22; // Angle of the sector in degrees
const MIN_LENGTH = 80; // Minimum length in mm
const MAX_LENGTH = 120; // Maximum length in mm

const DrawingApp = () => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [hoveredPoint, setHoveredPoint] = useState(null); // State to track the hovered point
  const [jointPoint, setJointPoint] = useState(null); // State to track the hovered point
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [draggingLine, setDraggingLine] = useState(null);
  const [dragStartPos, setDragStartPos] = useState(null);
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const { flag, updateFlag, resetFlag } = useContext(FlagContext);
  const [isUndoDisabled, setIsUndoDisabled] = useState(true);
  const [isRedoDisabled, setIsRedoDisabled] = useState(true);
  const [scale, setScale] = useState(1); // Scale state for zoom
  const stageRef = useRef();
  const BASE_URL = process.env.REACT_APP_BASE_API_URL;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [partition1, setPartition1] = useState(false);
  const [partition2, setPartition2] = useState(false);

  useEffect(() => {
    // updateFlag("isDesign", true);
    updateFlag({
      isDesign: true,
      TwoD: { 
        division: "",
        mode: "",
        type: "",
        divisionIndex: "",
        modeIndex: "",
        typeIndex: "",
      }
    });
    fetchRules();
    return () => updateFlag("isDesign", false); // Reset flag when component unmounts
  }, []);
  
  useEffect(() => {
    setIsUndoDisabled(history.length <= 1); // Disable Undo if history has only one state
    setIsRedoDisabled(redoStack.length === 0); // Disable Redo if redo stack is empty
  }, [history, redoStack]);
  async function fetchRules() {
    try {
      const response = await fetch(`${BASE_URL}/rules`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      updateFlag('Rules',data);
    } catch (error) {
      console.error("Error fetching rules:", error);
    }
  }

  const handleDialogClose = (obj1, obj2) => {

    if(obj1 && obj2) {
      updateFlag(
        "Data2D",
        flag.Data2D.map((line, index) => {
          if(line.id === obj1.id){
            return {...obj1}
          }
          else if(line.id === obj2.id){
            return {...obj2}
          }
          else return {...line}
        })
      )
    }
    else if(obj1) {
      updateFlag(
        "Data2D",
        flag.Data2D.map((line, index) => {
          if(line.id === obj1.id){
            if(dialogOpen === "GlassWidth"){
              const [x1, y1, x2, y2] = obj1.points;
              obj1.segments = splitIntoSegments_2(x1, y1, x2, y2, obj1);
            }
            return {...obj1}
          }
          else return {...line}
        })
      )
    }
    setDialogOpen(false);
    setJointPoint(null);
    setPartition1(false);
    setPartition2(false);
  };

  const renderGrid = () => {
    const gridLines = [];
    for (let i = 0; i < window.innerWidth / gridSize; i++) {
      gridLines.push(
        <Line
          key={`v${i}`}
          points={[i * gridSize, 0, i * gridSize, window.innerHeight]}
          stroke="#ddd"
          strokeWidth={0.8}
        />
      );
    }
    for (let i = 0; i < window.innerHeight / gridSize; i++) {
      gridLines.push(
        <Line
          key={`h${i}`}
          points={[0, i * gridSize, window.innerWidth, i * gridSize]}
          stroke="#ddd"
          strokeWidth={0.8}
        />
      );
    }
    return gridLines;
  };

  const isStateDifferent = (prevState, newState) => {
    return JSON.stringify(prevState) !== JSON.stringify(newState);
    function deepEqual(obj1, obj2) {
      if (typeof obj1 !== typeof obj2) return false;

      if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) return false;
        for (let i = 0; i < obj1.length; i++) {
          if (!deepEqual(obj1[i], obj2[i])) return false;
        }
        return true;
      }

      if (typeof obj1 === 'object' && obj1 !== null && obj2 !== null) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) return false;
        for (let key of keys1) {
          if (!deepEqual(obj1[key], obj2[key])) return false;
        }
        return true;
      }

      return obj1 === obj2;
    }

    function compareJSON(prevJSON, newJSON) {
      if (prevJSON.length !== newJSON.length) return false;

      for (let i = 0; i < prevJSON.length; i++) {
        // Compare each object in the array
        if (!deepEqual(prevJSON[i], newJSON[i])) return false;
      }

      return true;
    }

    // Additional function to check if `points` arrays are exactly the same
    function comparePoints(prevJSON, newJSON) {
      if (prevJSON.length !== newJSON.length) return false;

      for (let i = 0; i < prevJSON.length; i++) {
        const prevPoints = prevJSON[i].points;
        const newPoints = newJSON[i].points;

        if (prevPoints.length !== newPoints.length) return false;

        for (let j = 0; j < prevPoints.length; j++) {
          if (prevPoints[j] !== newPoints[j]) return false;
        }
      }

      return true;
    }


    if (prevState) {
      // Perform the comparison
      const isStructureAndValueEqual = prevState.length === prevState.length ? true : false
      const arePointsEqual = comparePoints(prevState, newState);

      if (isStructureAndValueEqual && arePointsEqual) {
        // console.log("The JSON structures are exactly the same.");
        return false
      } else {
        // console.log("The JSON structures are different.");
        return true
      }
    }
    else {
      return true
    }

  };

  // Save current state to history and clear redo stack
  const saveHistory = () => {
    if (isStateDifferent(flag.TwoD.history.length - 1, flag.Data2D)) {

      updateFlag("TwoD", { history: JSON.parse(JSON.stringify([...flag.TwoD.history, flag.Data2D])) })
      updateFlag("TwoD", { redoStack: [] });  // Clear redo stack after a new action
      updateFlag("TwoD", { isUndoDisabled: false }); // Enable Undo button
      updateFlag("TwoD", { isRedoDisabled: true }); // Disable Redo button
    }
  };

  const handlePointHover = (pointId) => {
    setHoveredPoint(pointId);
  };

  const handlePointLeave = () => {
    setHoveredPoint(null);
  };

  const renderPerpendicularMarker = (x1, y1, x2, y2, pointX, pointY, length) => {
    const angle = Math.atan2(y2 - y1, x2 - x1) + Math.PI / 2;
    const offsetX = (length / 2) * Math.cos(angle);
    const offsetY = (length / 2) * Math.sin(angle);

    return [
      pointX - offsetX, pointY - offsetY,
      pointX + offsetX, pointY + offsetY
    ];
  };

  const renderSeparationMarker = (segment, length) => {
    const { x1, y1, x2, y2 } = segment;
    const angle = Math.atan2(y2 - y1, x2 - x1) + Math.PI / 2;
    const offsetX = (length / 2) * Math.cos(angle);
    const offsetY = (length / 2) * Math.sin(angle);

    return [
      x1 - offsetX, y1 - offsetY,
      x1 + offsetX, y1 + offsetY
    ];
  };

  // Helper function to snap to the nearest grid point
  const snapToGrid = (pos) => {
    const x = Math.round(pos.x / gridSize) * gridSize;
    const y = Math.round(pos.y / gridSize) * gridSize;
    return { x, y };
  };

  const convertToInches = (mm) => {
    return mm / 25.4; // Convert millimeters to inches
  };

  // Helper function to calculate distance between two points
  const calculateDistance = (x1, y1, x2, y2) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  };

  // Helper function to calculate segments
  const splitIntoSegments = (x1, y1, x2, y2, maxLength) => {
    const totalLength = calculateDistance(x1, y1, x2, y2);
    const segments = [];

    if (totalLength <= maxLength) {
      segments.push({ x1, y1, x2, y2, length: totalLength });
    } else {
      let remainingLength = totalLength;
      let currentX1 = x1;
      let currentY1 = y1;

      while (remainingLength > maxLength) {
        const angle = Math.atan2(y2 - y1, x2 - x1);
        const segmentX2 = currentX1 + maxLength * Math.cos(angle);
        const segmentY2 = currentY1 + maxLength * Math.sin(angle);

        segments.push({
          x1: currentX1,
          y1: currentY1,
          x2: segmentX2,
          y2: segmentY2,
          length: maxLength,
        });

        currentX1 = segmentX2;
        currentY1 = segmentY2;
        remainingLength -= maxLength;
      }

      segments.push({
        x1: currentX1,
        y1: currentY1,
        x2,
        y2,
        length: remainingLength,
      });
    }

    return segments;
  };
  
  const splitIntoSegments_2 = (x1, y1, x2, y2, input) => {

    const maxLength = Number(input["Glass Width"]) || maxSegmentLength

    const totalLength = calculateDistance(x1, y1, x2, y2) * 10;
    const panelCount = Math.ceil(totalLength / maxLength);
    const panelSize = totalLength / panelCount;

    let currentX1 =  x1; 
    let currentY1 =  y1;

    let segments = []
    
    for (let i = 0; i < panelCount ; i++) {
        const nextX = currentX1 + (x2 - x1) * (panelSize / totalLength);
        const nextY = currentY1 + (y2 - y1) * (panelSize / totalLength);

        segments.push({
            x1: currentX1, y1: currentY1, x2: nextX, y2: nextY,
            length: calculateDistance(currentX1, currentY1, nextX, nextY),
            adjustedLength: calculateSegmentLength(
              totalLength,
              panelCount-1,
              input.hasLeftDoor || false,
              input.hasRightDoor || false,
              flag.Rules.data
              )
        });

        currentX1 = nextX;
        currentY1 = nextY;
    }

    return segments
  }


  const renderSectorShape = (start, end, doordirection) => {
    if (!start || !end) return null;

    // Calculate the length of the original line
    const length = calculateDistance(start.x, start.y, end.x, end.y);

    // Calculate the angle of the original line
    const angle = Math.atan2(end.y - start.y, end.x - start.x);

    // Calculate the radius and center for the quarter-circle
    const radius = length;
    let centerX, centerY, startAngle, endAngle, newEndX, newEndY;

    switch (doordirection) {
      case 0:
        newEndX = end.x - Math.cos(angle + Math.PI / 2) * length;
        newEndY = end.y - Math.sin(angle + Math.PI / 2) * length;
        centerX = end.x;
        centerY = end.y;
        startAngle = Math.atan2(start.y - centerY, start.x - centerX) * (180 / Math.PI);
        endAngle = startAngle + 90;
        break;
      case 1:
        newEndX = start.x - Math.cos(angle + Math.PI / 2) * length;
        newEndY = start.y - Math.sin(angle + Math.PI / 2) * length;
        centerX = start.x;
        centerY = start.y;
        startAngle = Math.atan2((start.y - Math.sin(angle + Math.PI / 2) * length) - centerY, (start.x - Math.cos(angle + Math.PI / 2) * length) - centerX) * (180 / Math.PI);
        endAngle = startAngle + 90;
        break;
      case 2:
        newEndX = end.x + Math.cos(angle + Math.PI / 2) * length;
        newEndY = end.y + Math.sin(angle + Math.PI / 2) * length;
        centerX = end.x;
        centerY = end.y;
        startAngle = Math.atan2((end.y + Math.sin(angle + Math.PI / 2) * length) - centerY, (end.x + Math.cos(angle + Math.PI / 2) * length) - centerX) * (180 / Math.PI);
        endAngle = startAngle + 90;
        break;
      case 3:
        newEndX = start.x + Math.cos(angle + Math.PI / 2) * length;
        newEndY = start.y + Math.sin(angle + Math.PI / 2) * length;
        centerX = start.x;
        centerY = start.y;
        startAngle = Math.atan2(end.y - centerY, end.x - centerX) * (180 / Math.PI);
        endAngle = startAngle + 90;
        break;
      default:
        break;
    }

    return (
      <>
        <Shape
          sceneFunc={(context, shape) => {
            context.beginPath();
            context.moveTo(centerX, centerY);
            context.lineTo(newEndX, newEndY);
            context.strokeShape(shape);
          }}
          stroke="green"
          strokeWidth={4}
          dash={[5, 5]}
        />
        <Shape
          sceneFunc={(context, shape) => {
            context.beginPath();
            context.arc(centerX, centerY, radius, startAngle * (Math.PI / 180), endAngle * (Math.PI / 180));
            context.strokeShape(shape);
          }}
          stroke="green"
          strokeWidth={2}
          dash={[5, 5]} // Optional: make the arc dashed if you prefer
        />
      </>
    );
  };

  const renderSlidingDoor = (start, end, Orientation) => {
    if (!start || !end || (Orientation !== "Left" && Orientation !== "Right")) return null;

    // Calculate the length and angle of the original line
    const length = calculateDistance(start.x, start.y, end.x, end.y);
    const angle = Math.atan2(end.y - start.y, end.x - start.x);

    // Define a fixed offset distance (10mm) above the line
    const offsetDistance = 10;

    // Calculate the offset for the extra line (above the original line by 10mm)
    const offsetX = Math.cos(angle - Math.PI / 2) * offsetDistance; // Offset perpendicular to the original line, going upwards
    const offsetY = Math.sin(angle - Math.PI / 2) * offsetDistance;

    // Calculate start and end percentages based on the Orientation ("Left" or "Right")
    let startPercent, endPercent;
    if (Orientation === "Left") {
      startPercent = 0; // 15% from the start of the line
      endPercent = 0.50;   // 65% from the start of the line
    } else if (Orientation === "Right") {
      startPercent = 0.50; // 35% from the start of the line
      endPercent = 1;   // 85% from the start of the line
    }

    // Calculate the new start and end points for the extra line based on percentages
    const extraLineStartX = start.x + Math.cos(angle) * (length * startPercent) + offsetX;
    const extraLineStartY = start.y + Math.sin(angle) * (length * startPercent) + offsetY;
    const extraLineEndX = start.x + Math.cos(angle) * (length * endPercent) + offsetX;
    const extraLineEndY = start.y + Math.sin(angle) * (length * endPercent) + offsetY;

    return (
      <>
        {/* Extra line above the original line */}
        <Shape
          sceneFunc={(context, shape) => {
            context.beginPath();
            context.moveTo(extraLineStartX, extraLineStartY);
            context.lineTo(extraLineEndX, extraLineEndY);
            context.strokeShape(shape);
          }}
          stroke="black"
          strokeWidth={4}
        // dash={[5, 5]} // Optional: dashed line style
        />
      </>
    );
  };


  const handleMouseDown_Line = (e) => {
    saveHistory();

    const pos = e.target.getStage().getPointerPosition();
    const snappedPos = snapToGrid(pos);

    let pointClicked = false;

    flag.Data2D.forEach((line, index) => {
      const startPoint = { x: line.points[0], y: line.points[1] };
      const endPoint = { x: line.points[2], y: line.points[3] };

      if (line.id === flag.isSelected && line.mode === "Partition") {
        // Check if any start or end point is clicked
        if (calculateDistance(snappedPos.x, snappedPos.y, startPoint.x, startPoint.y) < gridSize) {
          setSelectedPoint({ type: 'start', index });
          pointClicked = true;
        } else if (calculateDistance(snappedPos.x, snappedPos.y, endPoint.x, endPoint.y) < gridSize) {
          setSelectedPoint({ type: 'end', index });
          pointClicked = true;
        }

        // Check if the point is on the line segment itself
        const distanceToLine = Math.abs(
          (endPoint.y - startPoint.y) * snappedPos.x -
          (endPoint.x - startPoint.x) * snappedPos.y +
          endPoint.x * startPoint.y -
          endPoint.y * startPoint.x
        ) / calculateDistance(startPoint.x, startPoint.y, endPoint.x, endPoint.y);

        // Check if the clicked point is within the bounds of the line segment
        const isWithinSegmentBounds =
          snappedPos.x >= Math.min(startPoint.x, endPoint.x) &&
          snappedPos.x <= Math.max(startPoint.x, endPoint.x) &&
          snappedPos.y >= Math.min(startPoint.y, endPoint.y) &&
          snappedPos.y <= Math.max(startPoint.y, endPoint.y);

        if (!pointClicked && distanceToLine < 5 && isWithinSegmentBounds) {
          setDraggingLine(index);
          setDragStartPos(snappedPos);
          pointClicked = true;
        }
      }
    });

    if (!pointClicked) {
      updateFlag("isSelected", false)
      setIsDrawing(true);
      updateFlag("Data2D", [
        ...flag.Data2D,
        {
          points: [snappedPos.x, snappedPos.y],
          segments: [],
          id: uuidv4(),
          mode: "Partition",
          hasLeftDoor: false,  // Default door states
          hasRightDoor: false,
          division: flag.TwoD.division,
          type: flag.TwoD.type,
          ...flag["PartitionProperties"]
        },
      ]);
    }
  };


  const handleMouseMove_Line = (e) => {
    const stage = e.target.getStage();
    const pos = stage.getPointerPosition();
    const snappedPos = snapToGrid(pos);

    if (selectedPoint) {
      // Update the selected point (start or end) of the line
      let updatedLines = [...flag.Data2D];
      if (selectedPoint.type === 'start') {
        updatedLines[selectedPoint.index].points[0] = snappedPos.x;
        updatedLines[selectedPoint.index].points[1] = snappedPos.y;
      } else if (selectedPoint.type === 'end') {
        updatedLines[selectedPoint.index].points[2] = snappedPos.x;
        updatedLines[selectedPoint.index].points[3] = snappedPos.y;
      }

      const [x1, y1, x2, y2] = updatedLines[selectedPoint.index].points;
      updatedLines[selectedPoint.index].segments = splitIntoSegments_2(x1, y1, x2, y2, updatedLines[selectedPoint.index]);

      updateFlag("Data2D", updatedLines);

    } else if (draggingLine !== null && dragStartPos) {
      // Calculate the difference between the current mouse position and the starting position
      const dx = snappedPos.x - dragStartPos.x;
      const dy = snappedPos.y - dragStartPos.y;

      // Update the line's points based on the difference
      const updatedLines = [...flag.Data2D];
      const line = updatedLines[draggingLine];
      line.points = [
        line.points[0] + dx,
        line.points[1] + dy,
        line.points[2] + dx,
        line.points[3] + dy,
      ];

      const [x1, y1, x2, y2] = line.points;
      line.segments = splitIntoSegments_2(x1, y1, x2, y2, line);

      updateFlag("Data2D", updatedLines);
      setDragStartPos(snappedPos);
    }
    else if (isDrawing) {
      let lastLine = flag.Data2D[flag.Data2D.length - 1];
      lastLine.points = [lastLine.points[0], lastLine.points[1], snappedPos.x, snappedPos.y];

      const [x1, y1, x2, y2] = lastLine.points;
      lastLine.segments = splitIntoSegments_2(x1, y1, x2, y2, lastLine);

      updateFlag("Data2D", flag.Data2D.concat());
    }
  };

// Main calculation function
const calculateSegmentLength = (openingWidth, numberOfConnectors, hasLeftDoor, hasRightDoor, rules) => {
  let ruleName;

  if (hasLeftDoor && hasRightDoor) {
    ruleName = "Glass width for polycarbonate with two sides door";
  } else if (hasLeftDoor || hasRightDoor) {
    ruleName = "Glass width for polycarbonate with one end door";
  } else {
    ruleName = "Glass width for polycarbonate";
  }

  // Get the formula dynamically from API rules
  const rule = getRuleByName(rules, ruleName);
  const formula = rule.rule_expression;
  if (!formula) {
    throw new Error(`Formula for rule '${ruleName}' not found.`);
  }

  // Get constants dynamically
  const constants = getConstants(rules);

  // Replace variables in the formula
  const evaluatedFormula = formula
    .replace(/openingWidth/g, openingWidth)
    .replace(/numberOfConnectors/g, numberOfConnectors)
    .replace(/profile_width/g, constants.profile_width)
    .replace(/polycarbonate_width/g, constants.polycarbonate_width)
    .replace(/glass_insertion/g, constants.glass_insertion);

  // Evaluate the formula (use a math parser in production)
  const baseLength = eval(evaluatedFormula);

  return baseLength;
};


  const handleMouseUp_Line = (e) => {
    if (isDrawing) {
      setIsDrawing(false);
  
      const pos = e.target.getStage().getPointerPosition();
      const snappedPos = snapToGrid(pos);
  
      let lastLine = flag.Data2D[flag.Data2D.length - 1];
      lastLine.points = [lastLine.points[0], lastLine.points[1], snappedPos.x, snappedPos.y];
  
      const [x1, y1, x2, y2] = lastLine.points;
      const distance = calculateDistance(x1, y1, x2, y2);

      if (distance > 10) {
        
        // Check for doors connected to this partition's endpoints
        flag.Data2D.forEach((line) => {
          if (line.mode === 'Door') {
            const [dx1, dy1, dx2, dy2] = line.points;
    
            // If a door connects to the partition start point
            if ((x1 === dx1 && y1 === dy1) || (x1 === dx2 && y1 === dy2)) {
              lastLine.hasLeftDoor = true;
            }
    
            // If a door connects to the partition end point
            if ((x2 === dx1 && y2 === dy1) || (x2 === dx2 && y2 === dy2)) {
              lastLine.hasRightDoor = true;
            }
          }
        });
    
        lastLine.segments = splitIntoSegments_2(x1, y1, x2, y2, lastLine)
    
        // Replace the updated line in Data2D
        flag.Data2D.splice(flag.Data2D.length - 1, 1, lastLine);
        updateFlag("Data2D", flag.Data2D.concat());
      } 
      else {
        flag.Data2D.splice(flag.Data2D.length - 1, 1);
      }

      saveHistory();
    }
  
   
    if (isDrawing || selectedPoint || draggingLine !== null) {
      saveHistory();
    }

    setSelectedPoint(null);
    setDraggingLine(null);
    setDragStartPos(null);
  };

  const getValidIntersection = (line1, line2) => {
    const [x1, y1, x2, y2] = line1.points;
    const [x3, y3, x4, y4] = line2.points;
  
    const denom = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
    if (denom === 0) return null; // Parallel lines
  
    const px = ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) / denom;
    const py = ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) / denom;
  
    // Ensure the point lies within the endpoints of both segments
    const isPointOnSegment = (px, py, x1, y1, x2, y2) =>
      px >= Math.min(x1, x2) && px <= Math.max(x1, x2) &&
      py >= Math.min(y1, y2) && py <= Math.max(y1, y2);
  
    if (
      isPointOnSegment(px, py, x1, y1, x2, y2) &&
      isPointOnSegment(px, py, x3, y3, x4, y4)
    ) {
      return { x: px, y: py };
    }
  
    return null;
  };
  const calculateAngle = (points1, points2) => {
    const [x1, y1, x2, y2] = points1; // First line
    const [x3, y3, x4, y4] = points2; // Second line
  
    const vector1 = { x: x2 - x1, y: y2 - y1 };
    const vector2 = { x: x4 - x3, y: y4 - y3 };
  
    // Calculate the dot product
    const dotProduct = vector1.x * vector2.x + vector1.y * vector2.y;
  
    // Calculate magnitudes
    const magnitude1 = Math.sqrt(vector1.x ** 2 + vector1.y ** 2);
    const magnitude2 = Math.sqrt(vector2.x ** 2 + vector2.y ** 2);
  
    // Use the dot product formula to find the cosine of the angle
    const cosTheta = dotProduct / (magnitude1 * magnitude2);
  
    // Convert to angle in degrees
    const angleInRadians = Math.acos(Math.min(Math.max(cosTheta, -1), 1));
    const angleInDegrees = (angleInRadians * 180) / Math.PI;
  
    return Math.round(angleInDegrees);
  };
  

  // 90%hover
  const handleIntersectionHover = (e, ID, pt) => {
    const stage = stageRef.current;
    const pointerPosition = stage.getPointerPosition();
  
    let foundIntersection = false;
    flag.Data2D.forEach((line1) => {
      flag.Data2D.forEach((line2) => {
        if (
          line1.id !== line2.id &&
          (line1.points[0] == line2.points[0] || line1.points[0] == line2.points[3] || line1.points[3] == line2.points[0] || line1.points[3] == line2.points[3])
        ) {
          console.log("90%%%")
          const intersection = getValidIntersection(line1, line2);
          if (intersection && calculateAngle(line1.points, line2.points) > 88) {
            const distanceToIntersection = calculateDistance(
              pointerPosition.x,
              pointerPosition.y,
              intersection.x,
              intersection.y
            );
            console.log(distanceToIntersection)
            
            if (distanceToIntersection < 10) {
              console.log(distanceToIntersection)
              // alert('hello');
              setJointPoint(pt);
              foundIntersection = true;
              setDialogOpen("90");

              line1.isIntersecting = true
              if (!line1.intersectingId) {
                line1.intersectingId = []; // Initialize if it doesn't exist
              }    
              if (!line1.intersectingId.includes(line2.id)) {
                  line1.intersectingId.push(line2.id); // Push if not already present
              }
              if(!line1.intersectionJoint){
                line1.intersectionJoint = {}
                if( !line1.intersectionJoint[line2.id]){
                  line1.intersectionJoint[line2.id] = ""
                }
              }


              line2.isIntersecting = true
              if (!line2.intersectingId) {
                line2.intersectingId = []; // Initialize if it doesn't exist
              }    
              if (!line2.intersectingId.includes(line1.id)) {
                  line2.intersectingId.push(line1.id); // Push if not already present
              }
              if(!line2.intersectionJoint){
                line2.intersectionJoint = {}
                if( !line2.intersectionJoint[line1.id]){
                  line2.intersectionJoint[line1.id] = ""
                }
              }

              setPartition1(line1);
              setPartition2(line2);
            }
          }
        }
      });
    });
  
    if (!foundIntersection) {
      setJointPoint(null);
      setDialogOpen(false);
      setPartition1(false);
      setPartition2(false);
    }
  };
  

  const handleLineClick = (id) => {
    flag.Data2D.forEach((line, index) => {
      if (line.id === id) {

        let divisionIndex = flag.Properties.systems.findIndex(each => each.system_name === line.division);
        let modeIndex = flag.Properties.systems[divisionIndex].products.findIndex(each => each.product_name === line.mode);
        let typeIndex = flag.Properties.systems[divisionIndex].products[modeIndex].templates.findIndex(each => each.template_name === line.type);
        
          updateFlag({
            ExpandProperties: true,
            isSelected: line.id,
            TwoD: { 
              division: line.division,
              mode: line.mode,
              type: line.type,
              divisionIndex: divisionIndex,
              modeIndex: modeIndex,
              typeIndex: typeIndex,
            }
          });
        }
      });
  };

  const handleSegmentLineClick = (obj) => {
    setDialogOpen("GlassJoint");
    setPartition1(obj)
  }

  const handleLineRightClick = (e, line) => {
    setDialogOpen("GlassWidth");
    setPartition1(line)
  };
  
  const handleStageClick = (e) => {
    // Deselect all lines if clicking outside
    if (e.target === stageRef.current) {
      updateFlag("isSelected", false)
    }
  };

  const DoorDir = [
    {
      Orientation: "Right",
      Opening: "Push",
      doordirection: 0,
    },
    {
      Orientation: "Left",
      Opening: "Push",
      doordirection: 1,
    },
    {
      Orientation: "Right",
      Opening: "Pull",
      doordirection: 2,
    },
    {
      Orientation: "Left",
      Opening: "Pull",
      doordirection: 3,
    },
  ];

  const handleMouseDown_Door = (e) => {
    saveHistory();
    const pos = e.target.getStage().getPointerPosition();
    const snappedPos = snapToGrid(pos);

    let pointClicked = false;

    // Loop through the existing lines (doors)
    flag.Data2D.forEach((door, index) => {
      if (door.id === flag.isSelected && door.mode === "Door") {
        const startPoint = { x: door.points[0], y: door.points[1] };
        const endPoint = { x: door.points[2], y: door.points[3] };

        // Check if start or end point is clicked
        if (calculateDistance(snappedPos.x, snappedPos.y, startPoint.x, startPoint.y) < gridSize) {
          setSelectedPoint({ type: 'start', index });
          pointClicked = true;
        } else if (calculateDistance(snappedPos.x, snappedPos.y, endPoint.x, endPoint.y) < gridSize) {
          setSelectedPoint({ type: 'end', index });
          pointClicked = true;
        }

        // Check if the clicked point is on the line itself
        const distanceToLine = Math.abs(
          (endPoint.y - startPoint.y) * snappedPos.x -
          (endPoint.x - startPoint.x) * snappedPos.y +
          endPoint.x * startPoint.y -
          endPoint.y * startPoint.x
        ) / calculateDistance(startPoint.x, startPoint.y, endPoint.x, endPoint.y);

        const isWithinSegmentBounds = snappedPos.x >= Math.min(startPoint.x, endPoint.x) &&
          snappedPos.x <= Math.max(startPoint.x, endPoint.x) &&
          snappedPos.y >= Math.min(startPoint.y, endPoint.y) &&
          snappedPos.y <= Math.max(startPoint.y, endPoint.y);

        if (!pointClicked && distanceToLine < 5 && isWithinSegmentBounds) {
          setDraggingLine(index);
          setDragStartPos(snappedPos);
          pointClicked = true;
        }
      }
    });

    // If no point is clicked, start drawing a new door
    if (!pointClicked) {
      updateFlag("isSelected", false)
      setIsDrawing(true);
      let find = DoorDir.find(
        (each) =>
          each.Opening === flag["DoorProperties"].Opening &&
          each.Orientation === flag["DoorProperties"].Orientation
      );
      const Ddirection = find.doordirection;
      updateFlag("Data2D", [
        ...flag.Data2D,
        {
          points: [snappedPos.x, snappedPos.y],
          segments: [],
          start: snappedPos,
          end: null,
          sector: null,
          id: uuidv4(),
          doordirection: Ddirection,
          mode: "Door",
          division: flag.TwoD.division,
          type: flag.TwoD.type,
          ...flag["DoorProperties"]
        },
      ]);

    }
  };


  const handleMouseMove_Door = (e) => {
    const stage = e.target.getStage();
    const pos = stage.getPointerPosition();
    const snappedPos = snapToGrid(pos);

    if (selectedPoint) {
      let updatedLines = [...flag.Data2D];
      const door = updatedLines[selectedPoint.index];

      // Get the fixed point (start or end point that is not being dragged)
      const fixedPoint = selectedPoint.type === 'start' ? { x: door.points[2], y: door.points[3] } : { x: door.points[0], y: door.points[1] };

      // Calculate the distance between the fixed point and the dragged point
      const distance = calculateDistance(fixedPoint.x, fixedPoint.y, snappedPos.x, snappedPos.y);

      // Clamp the distance between MIN_LENGTH and MAX_LENGTH
      const clampedDistance = Math.max(MIN_LENGTH, Math.min(distance, MAX_LENGTH));

      // Calculate the angle between the fixed point and the dragged point
      const angle = Math.atan2(snappedPos.y - fixedPoint.y, snappedPos.x - fixedPoint.x);

      // Update the point being dragged, ensuring the clamped distance is applied
      if (selectedPoint.type === 'start') {
        door.points[0] = fixedPoint.x + Math.cos(angle) * clampedDistance;
        door.points[1] = fixedPoint.y + Math.sin(angle) * clampedDistance;
        door.start = { x: door.points[0], y: door.points[1] };
      } else if (selectedPoint.type === 'end') {
        door.points[2] = fixedPoint.x + Math.cos(angle) * clampedDistance;
        door.points[3] = fixedPoint.y + Math.sin(angle) * clampedDistance;
        door.end = { x: door.points[2], y: door.points[3] };
      }

      const [x1, y1, x2, y2] = door.points;
      door.segments = splitIntoSegments_2(x1, y1, x2, y2, door);

      updateFlag("Data2D", updatedLines);

    } else if (draggingLine !== null && dragStartPos) {
      const dx = snappedPos.x - dragStartPos.x;
      const dy = snappedPos.y - dragStartPos.y;

      let updatedLines = [...flag.Data2D];
      const door = updatedLines[draggingLine];

      door.points = [
        door.points[0] + dx,
        door.points[1] + dy,
        door.points[2] + dx,
        door.points[3] + dy
      ];

      // Update the start and end coordinates
      door.start = { x: door.points[0], y: door.points[1] };
      door.end = { x: door.points[2], y: door.points[3] };

      const [x1, y1, x2, y2] = door.points;
      door.segments = splitIntoSegments_2(x1, y1, x2, y2, door);

      updateFlag("Data2D", updatedLines);
      setDragStartPos(snappedPos);
    } else if (isDrawing) {
      // Keep existing door drawing logic
      let currentDoor = flag.Data2D[flag.Data2D.length - 1];
      const distance = calculateDistance(currentDoor.start.x, currentDoor.start.y, snappedPos.x, snappedPos.y);
      const clampedDistance = Math.max(MIN_LENGTH, Math.min(distance, MAX_LENGTH));

      const angle = Math.atan2(snappedPos.y - currentDoor.start.y, snappedPos.x - currentDoor.start.x);
      // const sectorDirection = snappedPos.x >= currentDoor.start.x ? 1 : -1;

      // const sectorLength = (clampedDistance * sectorAngle) / 100;
      // const sectorStartX = snappedPos.x + Math.cos(angle + Math.PI / 2) * sectorLength * sectorDirection;
      // const sectorStartY = snappedPos.y + Math.sin(angle + Math.PI / 2) * sectorLength * sectorDirection;

      currentDoor.points = [currentDoor.points[0], currentDoor.points[1], currentDoor.start.x + Math.cos(angle) * clampedDistance, currentDoor.start.y + Math.sin(angle) * clampedDistance];
      currentDoor.end = { x: currentDoor.start.x + Math.cos(angle) * clampedDistance, y: currentDoor.start.y + Math.sin(angle) * clampedDistance };
      // currentDoor.sector = {
      //   start: { x: sectorStartX, y: sectorStartY },
      //   end: { x: snappedPos.x, y: snappedPos.y },
      //   direction: sectorDirection
      // };

      const [x1, y1, x2, y2] = currentDoor.points;
      currentDoor.segments = splitIntoSegments_2(x1, y1, x2, y2, currentDoor);

      updateFlag("Data2D", flag.Data2D.concat());

    }
  };


  const handleMouseUp_Door = (e) => {
    if (isDrawing) {
        setIsDrawing(false);
        const pos = e.target.getStage().getPointerPosition();
        const snappedPos = snapToGrid(pos);
  
        let currentDoor = flag.Data2D[flag.Data2D.length - 1];
        const distance = calculateDistance(currentDoor.start.x, currentDoor.start.y, snappedPos.x, snappedPos.y);
        const clampedDistance = Math.max(MIN_LENGTH, Math.min(distance, MAX_LENGTH));
  
        if (distance > 10) {
            currentDoor.points = [
                currentDoor.points[0], currentDoor.points[1],
                currentDoor.start.x + Math.cos(Math.atan2(snappedPos.y - currentDoor.start.y, snappedPos.x - currentDoor.start.x)) * clampedDistance,
                currentDoor.start.y + Math.sin(Math.atan2(snappedPos.y - currentDoor.start.y, snappedPos.x - currentDoor.start.x)) * clampedDistance
            ];
            currentDoor.end = {
                x: currentDoor.start.x + Math.cos(Math.atan2(snappedPos.y - currentDoor.start.y, snappedPos.x - currentDoor.start.x)) * clampedDistance,
                y: currentDoor.start.y + Math.sin(Math.atan2(snappedPos.y - currentDoor.start.y, snappedPos.x - currentDoor.start.x)) * clampedDistance
            };

            let onLine = false
  
            flag.Data2D.forEach((line) => {
                if (line.mode === 'Partition') {
                    const [px1, py1, px2, py2] = line.points;
                    if (isOverlappingLine(currentDoor.points, line.points)) {
                        onLine = true
                        console.log("on the line");
                        handlePartitionDivision(line, currentDoor);
                    } else if (isNextToLine(currentDoor.points, line.points)) {
                        console.log("next to the line");
                        
                        if (isConnectedToStart(currentDoor.points, px1, py1)) {
                            line.hasLeftDoor = true; // Case 3
                        } else if (isConnectedToEnd(currentDoor.points, px2, py2)) {
                            line.hasRightDoor = true; // Case 1
                        }
                      //   Recalculate adjusted lengths after door placement
                        const [x1, y1, x2, y2] = line.points;                        
                        
                        line.segments = splitIntoSegments_2(x1, y1, x2, y2, line)
                    }
                }
            });

            if(!onLine){
              flag.Data2D.splice(flag.Data2D.length - 1, 1, currentDoor);
              updateFlag("Data2D", flag.Data2D.concat());
            }
  
        } else {
            flag.Data2D.splice(flag.Data2D.length - 1, 1);
        }
  
        saveHistory();
    }
  
    if (selectedPoint || draggingLine !== null) {
        saveHistory();
    }
  
    setSelectedPoint(null);
    setDraggingLine(null);
    setDragStartPos(null);
  };
  
  //Helper function to check overlap
  function isOverlappingLine(doorPoints, linePoints) {
    const [dx1, dy1, dx2, dy2] = doorPoints; // Door start and end
    const [lx1, ly1, lx2, ly2] = linePoints; // Line start and end
  
    // Check if the two points of the door are collinear with the partition and within bounds.
    return isPointOnLineSegment(dx1, dy1, lx1, ly1, lx2, ly2) &&
           isPointOnLineSegment(dx2, dy2, lx1, ly1, lx2, ly2);
  }
  
  // Helper function to check if a point lies on a line segment
  function isPointOnLineSegment(px, py, x1, y1, x2, y2) {
    const crossProduct = (py - y1) * (x2 - x1) - (px - x1) * (y2 - y1);
  
    if (Math.abs(crossProduct) > 1e-6) {
        return false; // Not collinear
    }
  
    const dotProduct = (px - x1) * (x2 - x1) + (py - y1) * (y2 - y1);
    if (dotProduct < 0) {
        return false; // Beyond start point
    }
  
    const squaredLength = (x2 - x1) ** 2 + (y2 - y1) ** 2;
    if (dotProduct > squaredLength) {
        return false; // Beyond end point
    }
  
    return true; // The point lies on the line segment
  }
  
  
  // Helper function to check if door is next to the line
  function isNextToLine(doorPoints, linePoints) {
    const [dx1, dy1, dx2, dy2] = doorPoints;
    const [lx1, ly1, lx2, ly2] = linePoints;
  
    return (
        (dx1 === lx1 && dy1 === ly1) || 
        (dx2 === lx2 && dy2 === ly2) || 
        (dx2 === lx1 && dy2 === ly1) || 
        (dx1 === lx2 && dy1 === ly2)
    );
  }
  
  // Helper to check start connection
  function isConnectedToStart(doorPoints, px1, py1) {
    return (doorPoints[0] === px1 && doorPoints[1] === py1) || (px1 === doorPoints[2] && py1 === doorPoints[3]);
  }
  
  
  
  // Updated isConnectedToEnd function
  function isConnectedToEnd(doorPoints, px2, py2) {
   return (px2 === doorPoints[0] && py2 === doorPoints[1]) ||
   (px2 === doorPoints[2] && py2 === doorPoints[3])
  }
  
function handlePartitionDivision(line, currentDoor) {
  const totalLength = calculateDistance(...line.points) * 10; // Total partition length in mm
  const doorWidth = calculateDistance(...currentDoor.points) * 10; // Door width in mm
  const remainingLength = totalLength - doorWidth; // Remaining partition length after door

  if (remainingLength > 0) {
    const maxPanelSize = Number(line["Glass Width"]) || maxSegmentLength // Maximum width of a single panel
    const panelCount = Math.ceil(remainingLength / maxPanelSize); // Calculate required panel count
    const panelSize = remainingLength / panelCount; // Distribute remaining length equally

    line.segments = []; // Reset segments

    const [lx1, ly1, lx2, ly2] = line.points; // Partition start and end points
    const [dx1, dy1, dx2, dy2] = currentDoor.points; // Door start and end points

    if (isConnectedToStart(currentDoor.points, lx1, ly1)) {
      // Case 1: Door is on the left
      line.hasLeftDoor = true;
      let currentX = dx2;
      let currentY = dy2;
      for (let i = 0; i < panelCount; i++) {
        const nextX = currentX + (lx2 - dx2) * (panelSize / remainingLength);
        const nextY = currentY + (ly2 - dy2) * (panelSize / remainingLength);

        line.segments.push({
          x1: currentX,
          y1: currentY,
          x2: nextX,
          y2: nextY,
          length: calculateDistance(currentX, currentY, nextX, nextY),
         adjustedLength:calculateSegmentLength(
            remainingLength,
            panelCount-1,
            true,
            false,
            flag.Rules.data
            )
        });

        if(i === 0){
          line.points[0] = currentX
          line.points[1] = currentY
        }

        currentX = nextX;
        currentY = nextY;
      }

      updateFlag("Data2D", [...flag.Data2D]);
    } 
    else if (isConnectedToEnd(currentDoor.points, lx2, ly2)) {
      line.hasRightDoor = true;
      let currentX =  lx1; // Start from the door's adjacent point
      let currentY =  ly1;
      for (let i = 0; i < panelCount ; i++) {
          const nextX = currentX + (lx2 - lx1) * (panelSize / totalLength);
          const nextY = currentY + (ly2 - ly1) * (panelSize / totalLength);

          line.segments.push({
              x1: currentX, y1: currentY, x2: nextX, y2: nextY,
              length: calculateDistance(currentX, currentY, nextX, nextY),
              adjustedLength: calculateSegmentLength(
                remainingLength,
                panelCount-1,
                false,
                true,
                flag.Rules.data
                )
          });

          if(i === panelCount-1){
            line.points[2] = nextX
            line.points[3] = nextY
          }

          currentX = nextX;
          currentY = nextY;
      }

      updateFlag("Data2D", [...flag.Data2D]);
    }
    else {
      // Case 3: Door is in the middle
      const partitionStart = { x: lx1, y: ly1 }; // Start of partition
      const doorStart = { x: dx1, y: dy1 }; // Door's start
      const doorEnd = { x: dx2, y: dy2 }; // Door's end
      const partitionEnd = { x: lx2, y: ly2 }; // End of partition

      let firstPart = JSON.parse(JSON.stringify(line))
      let lastPart = JSON.parse(JSON.stringify(line))

      firstPart.points[2] = dx1
      firstPart.points[3] = dy1

      lastPart.points[0] = dx2
      lastPart.points[1] = dy2

      const totalLengthfirstPart = calculateDistance(...firstPart.points) * 10;
      const totalLengthlastPart = calculateDistance(...lastPart.points) * 10;

      const panelCountfirstPart = Math.ceil(totalLengthfirstPart / maxPanelSize);
      const panelCountlastPart = Math.ceil(totalLengthlastPart / maxPanelSize);

      const panelSizefirstPart = totalLengthfirstPart / panelCountfirstPart;
      const panelSizelastPart = totalLengthlastPart / panelCountlastPart;

      // first part of partition
      const [LX1, LY1, LX2, LY2] = firstPart.points;
      let currentX1 =  LX1; 
      let currentY1 =  LY1;
      
      firstPart.hasRightDoor = true;
      for (let i = 0; i < panelCountfirstPart ; i++) {
          const nextX = currentX1 + (LX2 - LX1) * (panelSizefirstPart / totalLengthfirstPart);
          const nextY = currentY1 + (LY2 - LY1) * (panelSizefirstPart / totalLengthfirstPart);

          firstPart.segments.push({
              x1: currentX1, y1: currentY1, x2: nextX, y2: nextY,
              length: calculateDistance(currentX1, currentY1, nextX, nextY),
              adjustedLength: calculateSegmentLength(
                totalLengthfirstPart,
                panelCountfirstPart-1,
                false,
                true,
                flag.Rules.data
                )
          });

          currentX1 = nextX;
          currentY1 = nextY;
      }

      // last part of partition
      let currentX2 = dx2;
      let currentY2 = dy2;
      lastPart.hasLeftDoor = true;
      for (let i = 0; i < panelCountlastPart; i++) {
        const nextX = currentX2 + (lx2 - dx2) * (panelSizelastPart / totalLengthlastPart);
        const nextY = currentY2 + (ly2 - dy2) * (panelSizelastPart / totalLengthlastPart);

        lastPart.segments.push({
          x1: currentX2,
          y1: currentY2,
          x2: nextX,
          y2: nextY,
          length: calculateDistance(currentX2, currentY2, nextX, nextY),
         adjustedLength:calculateSegmentLength(
            totalLengthlastPart,
            panelCountlastPart-1,
            true,
            false,
            flag.Rules.data
            )
        });

        currentX2 = nextX;
        currentY2 = nextY;
      }

      line.points = firstPart.points
      line.segments = firstPart.segments
      
      flag.Data2D.splice(flag.Data2D.length - 1, 1, currentDoor);
      flag.Data2D.push(lastPart);
      updateFlag("Data2D", flag.Data2D.concat());

    }
  } else {
    line.segments = []; // No remaining partition if door covers full length
  }
}

  
  

  const calculateInnerAngle = (line1, line2, isStartPoint) => {
    
    const vector1 = { x: line1[2] - line1[0], y: line1[3] - line1[1] }; // Vector for line1
    const vector2 = { x: line2[2] - line2[0], y: line2[3] - line2[1] }; // Vector for line2
  
    const dotProduct = vector1.x * vector2.x + vector1.y * vector2.y;
    const magnitude1 = Math.sqrt(vector1.x ** 2 + vector1.y ** 2);
    const magnitude2 = Math.sqrt(vector2.x ** 2 + vector2.y ** 2);
  
    let angleInRadians = Math.acos(dotProduct / (magnitude1 * magnitude2));
    let angleInDegrees = (angleInRadians * 180) / Math.PI;
  
    // Ensure the angle is the inner angle (less than 180 degrees)
    if (angleInDegrees > 180) {
      angleInDegrees = 360 - angleInDegrees;
    }
  
    // Adjust the angle if line2 is at the end of line1 and we want the "left side" angle
    if (!isStartPoint) {
      angleInDegrees = 180 - angleInDegrees;
    }
  
    return angleInDegrees.toFixed(2); // Return the angle rounded to 2 decimal places
  };
  
  const calculateMidpointOnArc = (meetingPoint, radius, startAngle, endAngle) => {
    // Normalize angle difference to be within -π to π range
    let angleDifference = endAngle - startAngle;
    if (angleDifference > Math.PI) {
        angleDifference -= 2 * Math.PI;
    } else if (angleDifference < -Math.PI) {
        angleDifference += 2 * Math.PI;
    }

    // Calculate the midpoint angle along the shortest arc segment
    const midAngle = startAngle + angleDifference / 2;

    // Calculate the midpoint coordinates on the arc
    return {
        x: meetingPoint.x + radius * Math.cos(midAngle),
        y: meetingPoint.y + radius * Math.sin(midAngle),
    };
  };
  
  const calculateArcPath = (line1, line2, meetingPoint, angleInDegrees, isStartPoint) => {
    const radius = 30; // Radius for the arc indicating the angle

    // Determine the vectors based on `isStartPoint`
    const vector1 = isStartPoint ?
        { x: line1[2] - meetingPoint.x, y: line1[3] - meetingPoint.y }
        :
        { x: line1[0] - meetingPoint.x, y: line1[1] - meetingPoint.y };
      
    const vector2 = { x: line2[2] - meetingPoint.x, y: line2[3] - meetingPoint.y };

    // Calculate starting and ending angles
    let startAngle = Math.atan2(vector1.y, vector1.x);
    let endAngle = Math.atan2(vector2.y, vector2.x);

    // Calculate the angle difference to ensure it always represents the inner angle
    let angleDifference = endAngle - startAngle;

    // Normalize the angle difference to be within -π to π range
    if (angleDifference > Math.PI) {
      angleDifference -= 2 * Math.PI;
    } else if (angleDifference < -Math.PI) {
      angleDifference += 2 * Math.PI;
    }

    // Flip start and end angles if necessary to represent the inner angle arc correctly
    if (angleDifference < 0) {
      [startAngle, endAngle] = [endAngle, startAngle];
    }

    // Calculate the midpoint on the arc for the degree label
    const labelPosition = calculateMidpointOnArc(meetingPoint, radius, startAngle, endAngle, isStartPoint);

    // Return the arc path details with adjusted angles and label position
    return {
      x: meetingPoint.x,
      y: meetingPoint.y,
      radius,
      startAngle,
      endAngle: startAngle + Math.abs(angleDifference),
      labelPosition, // Midpoint for placing the degree label
    };
  };
  
  // Update the main function to include arcs
  const findMeetingPointsAndDisplayAngles = (lines) => {
    let anglesToDisplay = [];
    let meetingPoints = {};
  
    // Group lines by their start and end points as before
    lines.forEach((line, index) => {
      if(line.mode === "Partition"){
        const [x1, y1, x2, y2] = line.points;
        const startPointKey = `${x1},${y1}`;
        const endPointKey = `${x2},${y2}`;
    
        if (!meetingPoints[startPointKey]) meetingPoints[startPointKey] = [];
        meetingPoints[startPointKey].push({ line, isStartPoint: true, index });
    
        if (!meetingPoints[endPointKey]) meetingPoints[endPointKey] = [];
        meetingPoints[endPointKey].push({ line, isStartPoint: false, index });
      }
    });
  
    // Calculate angles and arcs for display
    Object.keys(meetingPoints).forEach((pointKey) => {
      const linesAtPoint = meetingPoints[pointKey];
      const [meetingPointX, meetingPointY] = pointKey.split(",").map(Number);
  
      for (let i = 0; i < linesAtPoint.length; i++) {
        for (let j = i + 1; j < linesAtPoint.length; j++) {
          const lineInfo1 = linesAtPoint[i];
          const lineInfo2 = linesAtPoint[j];
  
          const angle = calculateInnerAngle(
            lineInfo1.line.points,
            lineInfo2.line.points,
            lineInfo1.isStartPoint
          );
  
          const meetingPoint = { x: meetingPointX, y: meetingPointY };
  
          // Calculate the arc path for the angle curve
          const arcPath = calculateArcPath(
            lineInfo1.line.points,
            lineInfo2.line.points,
            meetingPoint,
            angle,
            lineInfo1.isStartPoint
          );  
  
          // Store the angle with the calculated midpoint position and arc path
          anglesToDisplay.push({
            angle,
            arc: arcPath,
          });
        }
      }
    });
  
    return anglesToDisplay;
  };  

  const anglesToDisplay = findMeetingPointsAndDisplayAngles(flag.Data2D);

  const minScale = 0.5;  // Minimum zoom level (50%)
  const maxScale = 2;    // Maximum zoom level (200%)

  const handleWheel = (e) => {
    e.evt.preventDefault();
    const scaleBy = 1.1;
    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    // Calculate new scale and apply min/max constraints
    let newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;
    newScale = Math.max(minScale, Math.min(maxScale, newScale));

    setScale(newScale);
    stage.scale({ x: newScale, y: newScale });

    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };
    stage.position(newPos);
    stage.batchDraw();
  };


  return (
    <div id="drawing-outline" style={{ opacity: dialogOpen ? 0.4 : 1, // Set opacity to 0.2 when the dialog is open, otherwise 1
pointerEvents: dialogOpen ? 'none' : 'auto'}}>
      {dialogOpen && <div>
      <CustomDialog type={dialogOpen} partition1={partition1} partition2={partition2} onClose={handleDialogClose} />
      </div>}
      <div style={{ position: 'absolute', zIndex: '9999', marginTop: '-1.5%', marginLeft: '0%'}}>
        <Orders />
      </div>
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        // scaleX={scale}
        // scaleY={scale}
        // onWheel={handleWheel}
        onMouseDown={(e) => {
          if (flag.TwoD.mode === "Partition") { handleMouseDown_Line(e); }
          else if (flag.TwoD.mode === "Door") { handleMouseDown_Door(e); }
        }}
        onMousemove={(e) => {
          // if (flag.TwoD.mode === "Partition") {handleMouseMove_Line(e);handleIntersectionHover(e);}
          if (flag.TwoD.mode === "Partition") {handleMouseMove_Line(e);}
          else if (flag.TwoD.mode === "Door") handleMouseMove_Door(e)
        }}
        onMouseup={(e) => {
          if (flag.TwoD.mode === "Partition") handleMouseUp_Line(e)
          else if (flag.TwoD.mode === "Door") handleMouseUp_Door(e)
        }}       
        ref={stageRef}
      >
        <Layer>
          {flag.TwoD.gridVisible && renderGrid()}
          {flag.Data2D.map((line, i) => {
            const totalLengthMM = line.segments.reduce((sum, segment) => sum + segment.length, 0);
            if (line.mode === "Partition" && totalLengthMM > 10) {
              const isHighlighted = draggingLine === i; // Check if this line is being dragged
              const totalLength = flag.TwoD.unit === 'mm' ? Math.round(totalLengthMM * 10) : convertToInches(totalLengthMM * 10).toFixed(2);
              const midX = (line.points[0] + line.points[2]) / 2;
              const midY = (line.points[1] + line.points[3]) / 2;

              return (
                <React.Fragment key={i}>
                  {line.segments.map((segment, j) => {
                    const segmentMidX = (segment.x1 + segment.x2) / 2;
                    const segmentMidY = (segment.y1 + segment.y2) / 2;
                    const segmentLengthMM = segment.length * 10;
                    const segmentLength = flag.TwoD.unit === 'mm' ?  Math.round(segmentLengthMM) : convertToInches(segmentLengthMM).toFixed(2);

                    // Determine if the line is vertical or not
                    const isVertical = segment.x1 === segment.x2;

                    return (
                      <React.Fragment key={`${i}-${j}`}>
                        <Line
                          points={[segment.x1, segment.y1, segment.x2, segment.y2]}
                          strokeWidth={5}
                          stroke={line.id === flag.isSelected ? 'blue' : 'black'}                     
                          onClick={(e) => {
                            if (e.evt.button === 0) { // Left-click only
                              handleLineClick(line.id);
                            }
                          }}
                          onContextMenu={(e) => {
                            e.evt.preventDefault(); // Prevent the default browser context menu
                            e.cancelBubble = true;  // Stop the event from propagating to parent components
                            handleLineRightClick(e, line); // Handle right-click logic
                          }}
                          lineCap="round"
                          lineJoin="round"
                        />
                        {hoveredPoint && (
                        <Circle
                        x={hoveredPoint.x}
                        y={hoveredPoint.y}
                        radius={7}
                        fill="blue"
                        stroke="blue" // Optional: add stroke to make the circle outline visible
                        strokeWidth={1} // Optional: adjust stroke width
                      />
                        )}
                        <Text
                          x={isVertical ? segmentMidX - 40 : segmentMidX} // For vertical lines, display text on the left
                          y={segmentMidY + (isVertical ? 0 : 10)} // Position text based on whether it's vertical or not
                          //text={`${segmentLength} ${flag.TwoD.unit}`}
                          text={`${Math.round(segment.adjustedLength)} ${flag.TwoD.unit}`}
                          fontSize={10}
                          fill="black"
                        />
                        <Line
                          points={renderSeparationMarker(segment, 10)} // For each separation marker
                          stroke={hoveredPoint === `${i}-${j}` ? "#ffc93c" : "black"}
                          strokeWidth={hoveredPoint === `${i}-${j}` ? 7 : 5}
                          onClick={() => handleSegmentLineClick(line)}
                          onMouseEnter={() => handlePointHover(`${i}-${j}`)}
                          onMouseLeave={handlePointLeave}
                        />
                      </React.Fragment>
                    );
                  })}

                  <Line
                    points={renderPerpendicularMarker(line.points[0], line.points[1], line.points[2], line.points[3], line.points[0], line.points[1], 10)} // For start point marker
                    stroke={jointPoint === `start-${i}` ? "red" : hoveredPoint === `start-${i}` ? "#ffc93c" : "#79c2d0"} // Start Point
                    strokeWidth={hoveredPoint === `start-${i}` ? 8 : 6}
                    onClick={(e) => handleIntersectionHover(e, line.id, `start-${i}`)}
                    onMouseEnter={() => handlePointHover(`start-${i}`)}
                    onMouseLeave={handlePointLeave}
                  />
                  <Line
                    points={renderPerpendicularMarker(line.points[0], line.points[1], line.points[2], line.points[3], line.points[2], line.points[3], 10)} // End point marker
                    stroke={jointPoint === `end-${i}` ? "red" : hoveredPoint === `end-${i}` ? "#ffc93c" : "#79c2d0"} // End Point
                    strokeWidth={hoveredPoint === `end-${i}` ? 8 : 6}
                    onClick={(e) => handleIntersectionHover(e, line.id, `end-${i}`)}
                    onMouseEnter={() => handlePointHover(`end-${i}`)}
                    onMouseLeave={handlePointLeave}
                  />
                  <Line
                    points={line.points}
                    stroke={isHighlighted ? "red" : null} // Highlight with red stroke if selected
                    strokeWidth={isHighlighted ? 5 : null} // Make the line slightly thicker when highlighted
                    lineCap="round"
                    lineJoin="round"
                  />
                  <Text
                    x={line.points[0] === line.points[2] ? line.points[2] + 10 : midX} // For vertical lines, display text on the right
                    y={line.points[0] === line.points[2] ? midY : midY - 20} // Adjust positioning based on vertical/horizontal
                    text={totalLength ? `${totalLength} ${flag.TwoD.unit}` : null}
                    fontSize={10}
                    fill="black"
                  />
                </React.Fragment>
              );
            }
          })}

          {/* Render the angles at meeting points */}
          {
            // Rendering angles and arcs in the UI
            anglesToDisplay.map((angleInfo, i) => {
              if(angleInfo.angle !== "NaN"){
                return (
                  <React.Fragment key={i}>
                    {/* Draw the arc for the angle */}
                    <Arc
                      x={angleInfo.arc.x}
                      y={angleInfo.arc.y}
                      innerRadius={angleInfo.arc.radius - 2} // Slightly smaller radius to keep arc width
                      outerRadius={angleInfo.arc.radius}
                      angle={angleInfo.angle} // Sweep angle in degrees
                      rotation={angleInfo.arc.startAngle * (180 / Math.PI)} // Convert to degrees
                      stroke="gray"
                      strokeWidth={1.5}
                    />
    
                    {/* Display the angle text near the arc */}
                    <Text
                      x={angleInfo.arc.labelPosition.x}
                      y={angleInfo.arc.labelPosition.y}
                      text={`${angleInfo.angle}°`}
                      fontSize={12}
                      fill="blue"
                    />
                  </React.Fragment>
                )
              }
            })
          }

          {flag.Data2D.map((door, index) => {
            const isHighlighted = draggingLine === index; // Check if this line is being dragged
            const { start, end } = door;
            const totalLengthMM = calculateDistance(door.points[0], door.points[1], door.points[2], door.points[3]);
            const totalLength = flag.TwoD.unit === 'mm' ?  Math.round(totalLengthMM * 10) : convertToInches(totalLengthMM * 10).toFixed(2);
            const midX = (door.points[0] + door.points[2]) / 2;
            const midY = (door.points[1] + door.points[3]) / 2;

            // Split the line into two parts
            const midPoint1X = (door.points[0] + midX) / 2; // Midpoint of the first half
            const midPoint1Y = (door.points[1] + midY) / 2;
            const midPoint2X = (midX + door.points[2]) / 2; // Midpoint of the second half
            const midPoint2Y = (midY + door.points[3]) / 2;

            // Split the total length in half (e.g., 1000mm becomes two 500mm segments)
            const halfLength = (totalLength / 2);

            if (!start || !end) return null; // Skip rendering if start or end is missing

            return (
              <React.Fragment key={index}>
                <Line
                  points={[start.x, start.y, end.x, end.y]}
                  stroke={isHighlighted ? "red" : door.id === flag.isSelected ? 'blue' : 'green'}
                  strokeWidth={door.id === flag.isSelected ? 6 : 5}
                  onClick={() => handleLineClick(door.id)}
                />
                {door.type === "Sliding Door" && renderSlidingDoor(start, end, door.Orientation)}
                {door.type === "Stile Door - Single leaf" && renderSectorShape(start, end, door.doordirection)}

                {/* Start Point Marker */}
                <Line
                  points={renderPerpendicularMarker(door.points[0], door.points[1], door.points[2], door.points[3], door.points[0], door.points[1], 10)}
                  stroke={hoveredPoint === `start-${index}` ? "#ffc93c" : "#79c2d0"}
                  strokeWidth={hoveredPoint === `start-${index}` ? 8 : 6}
                  onMouseEnter={() => handlePointHover(`start-${index}`)}
                  onMouseLeave={handlePointLeave}
                />

                {/* Middle Point Marker */}
                {door.type === "Sliding Door" &&
                <Line
                  points={renderPerpendicularMarker(door.points[0], door.points[1], door.points[2], door.points[3], midX, midY, 10)}
                  stroke={hoveredPoint === `mid-${index}` ? "#ffc93c" : "#79c2d0"}
                  strokeWidth={hoveredPoint === `mid-${index}` ? 8 : 6}
                  onMouseEnter={() => handlePointHover(`mid-${index}`)}
                  onMouseLeave={handlePointLeave}
                />
                }

                {/* End Point Marker */}
                <Line
                  points={renderPerpendicularMarker(door.points[0], door.points[1], door.points[2], door.points[3], door.points[2], door.points[3], 10)}
                  stroke={hoveredPoint === `end-${index}` ? "#ffc93c" : "#79c2d0"}
                  strokeWidth={hoveredPoint === `end-${index}` ? 8 : 6}
                  onMouseEnter={() => handlePointHover(`end-${index}`)}
                  onMouseLeave={handlePointLeave}
                />

                {/* Measurement Text below the line */}
                <Text
                  x={door.points[0] === door.points[2] ? door.points[2] + 10 : midX - 15} // For vertical lines, display text on the right
                  y={door.points[0] === door.points[2] ? midY : midY + 10} // Adjust positioning based on vertical/horizontal (moved below the line)
                  text={`${totalLength} ${flag.TwoD.unit}`}
                  fontSize={10}
                  fill="black"
                />

                {door.type === "Sliding Door" &&
                <>
                <Text
                  x={door.points[0] === door.points[2] ? midPoint1X - 40 : midPoint1X - 10} // For vertical lines, display text on the right
                  y={door.points[0] === door.points[2] ? midPoint1Y : midPoint1Y - 20} // Adjust positioning below the line
                  text={`${halfLength} ${flag.TwoD.unit}`}
                  fontSize={10}
                  fill="black"
                />

                {/* Measurement for Second Half */}
                <Text
                  x={door.points[0] === door.points[2] ? midPoint2X - 40 : midPoint2X - 10} // For vertical lines, display text on the right
                  y={door.points[0] === door.points[2] ? midPoint2Y : midPoint2Y - 20} // Adjust positioning below the line
                  text={`${halfLength} ${flag.TwoD.unit}`}
                  fontSize={10}
                  fill="black"
                />
                </>
                }

              </React.Fragment>
            );
          })}


        </Layer>
      </Stage>
    </div>
  );
};

export default DrawingApp;