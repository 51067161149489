import React, { useState, useContext } from 'react';
import { ProjectContext } from './ProjectContext'; // Correct path to ProjectContext
import {
  TextField, Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ProjectForm = () => {
  const { addOrUpdateProject } = useContext(ProjectContext);  // Access context
  const [formData, setFormData] = useState({
    project_name: '',
    customer_name: '',
    project_location: '',
    business_name: '',
    start_date: '',
    end_date: '',
    project_status: 'In progress', // Default status
  });
  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addOrUpdateProject(formData);  // Use context function to add or update project
    setFormData({
      project_name: '',
      customer_name: '',
      project_location: '',
      business_name: '',
      start_date: '',
      end_date: '',
      project_status: 'In progress', // Reset to default status
    });
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add Project
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Add New Project
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingTop: '2%' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Project Name"
                  name="project_name"
                  value={formData.project_name}
                  onChange={handleChange}
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                    style: { fontFamily: 'Poppins', fontWeight: 500, fontSize: '14px', color: '#333' },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Customer Name"
                  name="customer_name"
                  value={formData.customer_name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Project Location"
                  name="project_location"
                  value={formData.project_location}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Business"
                  name="business_name"
                  value={formData.business_name}
                  onChange={handleChange}
                  fullWidth
                  required
                  select
                >
                  <MenuItem value="Glass">Glass</MenuItem>
                  <MenuItem value="Wood">Wood</MenuItem>
                  <MenuItem value="Metal">Metal</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  type="date"
                  label="Start Date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={handleChange}
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  type="date"
                  label="End Date"
                  name="end_date"
                  value={formData.end_date}
                  onChange={handleChange}
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Project Status"
                  name="project_status"
                  value={formData.project_status}
                  onChange={handleChange}
                  fullWidth
                  required
                  select
                >
                  <MenuItem value="In progress">In progress</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleClose} variant="contained" color="secondary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Create Project
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProjectForm;